import styled from "styled-components";
import { variant } from "styled-system";
export const UiPagination = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.Hide {
		display: none;
	}

	ul {
		display: flex;
		margin-top: 0;
		list-style: none;
		margin-bottom: 0;

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 5px;
			margin-right: 8px;
			min-width: 30px;
			height: 30px;
			text-align: center;
			vertical-align: middle;

			& > a {
				color: ${({
  textcolor
}) => textcolor || "#fff"};
			}

			& > .ant-pagination-item-link {
				color: ${({
  textcolor
}) => textcolor || "#fff"};

				&:hover {
					color: #1890ff;
				}
			}

			.anticon {
				vertical-align: baseline;
			}

			&.ant-pagination-item-active {
				a {
					color: #1890ff;
					${variant({
  variants: {
    default: {
      color: "#1890ff"
    }
  }
})}
				}
				${variant({
  variants: {
    default: {
      borderColor: "#1890ff"
    }
  }
})}
			}

			&.page {
				padding: 2.5px 5px;
				cursor: pointer;

				&.active {
					color: #1890ff;
					border: 1px solid #1890ff;
				}

				&.disabled {
					color: rgba(0, 0, 0, 0.25);
					border-color: 1px solid #d9d9d9;
					cursor: not-allowed;
				}
			}

			&.dot {
				color: #fff;
			}
			${variant({
  variants: {
    default: {
      backgroundColor: "transparent",
      border: "1px solid transparent"
    }
  }
})}
		}

		.ant-pagination-item-link {
			&.ant-pagination-disabled {
				${variant({
  variants: {
    default: {
      color: "#d9d9d9"
    }
  }
})}
			}

			&:hover {
				${variant({
  variants: {
    default: {
      color: "#fff"
    }
  }
})}
			}

			${variant({
  variants: {
    default: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      backgroundColor: "transparent",
      borderColor: "transparent"
    }
  }
})}
		}

		.ant-pagination-item-ellipsis {
			${variant({
  variants: {
    default: {
      color: "#fff!important"
    }
  }
})}
		}
	}
`;