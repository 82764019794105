import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "common-components";
import { UiEditGroup } from "./EditGroup.style";

/**
 * 編輯按鈕套組
 */

export const EditGroup = ({ size = "medium", onSave, onEdit, onCancel }) => {
	const [isEditState, setEditState] = useState(false);

	const saveHandler = () => {
		setEditState(false);
		onSave();
	};

	const editHandler = () => {
		setEditState(true);
		onEdit();
	};

	const cancelHandler = () => {
		setEditState(false);
		onCancel();
	};

	return (
		<UiEditGroup>
			{!isEditState && (
				<Button.IconButton size={size} variant="blue" iconName="edit" full={false} onClick={editHandler}>
					編輯
				</Button.IconButton>
			)}
			{isEditState && (
				<>
					<Button.IconButton size={size} variant="white" full={false} iconName="close" mr={2} onClick={cancelHandler}>
						取消
					</Button.IconButton>
					<Button.IconButton
						size={size}
						variant="green"
						full={false}
						iconName="save"
						type="outLined"
						onClick={saveHandler}>
						儲存
					</Button.IconButton>
				</>
			)}
		</UiEditGroup>
	);
};

EditGroup.propTypes = {
	size: PropTypes.string,
	onSave: PropTypes.func,
	onEdit: PropTypes.func,
	onCancel: PropTypes.func,
};
