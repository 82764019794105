import styled from "styled-components";

export const UiRuleAddContent = styled.div`
	display: block;
`;

export const UiTitleHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	font-size: 20px;
	font-weight: 500;
	color: #1890ff;
`;

export const UiClearButton = styled.button`
	display: flex;
	cursor: pointer;
	align-items: center;
	margin-left: 8px;
	padding: 1px 8px;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.65);
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: none;

	.text {
		margin-left: 10px;
	}
`;

export const UiCompleteStep = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.desc {
		font-size: 24px;
	}

	.tip {
		margin-top: 19px;
		font-size: 16px;
		color: #1890ff;
	}

	> .action {
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
`;

export const UiRuleAddProductContent = styled.div`
	.formItem {
		margin: 24px 0;
	}

	.volumeItem {
		display: flex;

		.ant-checkbox-group-item {
			margin-bottom: 24px;
		}
	}

	.volumeItemLabel {
		margin-right: 15px;
		font-size: 15px;
		flex: 0 0 60px;
	}
`;
