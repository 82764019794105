import { AUTHORITY, ITEM_TYPE, EDUCATIONAL, AUTHORIZE_TYPE } from "constants/index";

export const arraysEqual = (a, b) => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (var i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
};

export const sliceTableData = (data, currentPage, limit) => {
	return data.slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit);
};

export const setSelectOptions = (data, needNull = false) => {
	if (!data) return [];
	const result =
		data.length > 0
			? data.map((item) => {
					return { name: item.name, value: item.code };
			  })
			: [];

	if (needNull) {
		result.push({
			name: "---",
			value: "",
		});
	}
	return result;
};

export const blobDownload = (url, filename, type) => {
	const xhr = new XMLHttpRequest();
	xhr.open(type, url, true);
	xhr.responseType = "blob";
	const requestOnload = () => {
		xhr.onload = function (e) {
			if (this.status === 200) {
				const blob = this.response;
				const a = document.createElement("a");
				document.body.appendChild(a);
				const blobUrl = window.URL.createObjectURL(blob);
				a.href = blobUrl;
				a.download = filename;
				a.click();
				setTimeout(() => {
					window.URL.revokeObjectURL(blobUrl);
					document.body.removeChild(a);
				}, 0);
			}
		};
	};
	if (type === "POST") {
		xhr.setRequestHeader("Content-type", "application/json");
		requestOnload();
	}
	if (type === "GET") {
		requestOnload();
		xhr.send();
	}
};

const formatAuthority = (authorityValue) => {
	const authArray = authorityValue.toString(2).split("");
	let result = {};
	Object.entries(AUTHORITY).forEach(([key, value], index) => {
		result = { [key]: parseInt(authArray[index] || 0), ...result };
	});
	return result;
};

export const getTableAuthority = (type, authData) => {
	if (!authData || !type) {
		return {
			authority: 0,
			tableAuthority: {
				View: 0,
				Edit: 0,
				Lock: 0,
				Import: 0,
			},
		};
	}
	const authTableType = type.indexOf("_") !== -1 ? type.split("_")[0] + type.split("_")[1] : type;
	const { table, authority } = authData;
	const tableAuthority = table[authTableType.toUpperCase()] || 0;
	return {
		authority,
		tableAuthority: formatAuthority(tableAuthority),
	};
};

export const convertArrayToMap = (arr, key) =>
	arr.reduce((acc, v) => {
		if (v[key]) {
			acc[v[key]] = v;
		}
		return acc;
	}, {});

export const groupArr = (array, subGroupLength) => {
	var index = 0;
	var newArray = [];

	while (index < array.length) {
		newArray.push(array.slice(index, (index += subGroupLength)));
	}

	return newArray;
};

export const search = (target, compare) => compare.indexOf(target) !== -1;

export const filterItemType = ({ data, eduSubject }) => {
	console.log(eduSubject);
	const subject = eduSubject[data.education].find((sub) => sub.code === data.subject).name;
	let type = [];
	ITEM_TYPE.map((info) => {
		type.push({ ...info, value: info.name });
		return null;
	});

	const tempArr = [];
	let items = type.filter((info) => {
		if (
			info.subject === subject &&
			info.education === data.education &&
			!tempArr.find((obj) => obj.name === info.name)
		) {
			tempArr.push(info);
			return info;
		}
	});
	return items;
};

export const filterAuthorizeType = ({ data, eduSubject }) => {
	const subject = eduSubject[data.education].find((sub) => sub.code === data.subject).name;
	const education = EDUCATIONAL.find((edu) => edu.value === data.education).name;

	const type = AUTHORIZE_TYPE.map((info) => {
		return { ...info, value: info.name };
	});

	const tempArr = [];
	let items = type.filter((info) => {
		if (info.key === education + subject && !tempArr.find((obj) => obj.name === info.name)) {
			tempArr.push(info);
			return info;
		}
	});

	return items;
};

export const filterAttributeType = ({ data, eduSubject }) => {
	const subject = eduSubject[data.education].find((sub) => sub.code === data.subject).name;
	let type = [];
	console.log(ITEM_TYPE);
	ITEM_TYPE.map((info) => {
		type.push({ ...info, name: info.attribute, value: info.attribute });
		return null;
	});
	console.log(type);

	const tempArr = [];
	let items = type.filter((info) => {
		//console.log(info,info.subject, subject, info.education);
		if (
			info.subject === subject &&
			info.education === data.education &&
			!tempArr.find((obj) => obj.name === info.name)
		) {
			tempArr.push(info);
			return info;
		}
	});
	return items;
};
