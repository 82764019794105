import { Api } from "common-components";

/**
 * 上傳試題
 */
export const convertQueue = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Convert/Queue`, payload);
	return response;
};

/**
 * 取得檔案列表
 */
export const getRecord = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/Record`);
	return response;
};

/**
 * 取得檔案詳細
 */
export const getRecordDetail = async (eduSubject, fileUID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/${eduSubject}/File/${fileUID}`);
	return response;
};

/**
 * 刪除檔案
 */
export const deleteRecordFile = async (fileUID) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Convert/hide/${fileUID}`);
	return response;
};

/**
 * 查詢修改轉檔列表
 */
export const getRecordEdit = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/Record/Edit`);
	return response;
};

/**
 * 寫入修改轉檔排程
 */
export const convertQueueEdit = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Convert/Queue/Edit`, payload);
	return response;
};
