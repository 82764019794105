import styled from "styled-components";

export const UiDragDropList = styled.div`
	display: block;
	padding-bottom: 12px;
	background-color: #fff;

	.title {
		margin-bottom: 12px;
		color: #686868;
	}

	.message {
		display: flex;
		align-items: center;
		padding: 12px 4px;
		color: #f00;

		i {
			margin-right: 4px;
		}
	}
`;

export const UiDragDropContainer = styled.div`
	display: block;
`;

export const UiDragDropItem = styled.div`
	display: flex;
	align-items: center;
	width: max-content;

	.drop {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
		color: #686868;

		& i {
			font-size: 18px;
		}
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: #686868;

		& i {
			font-size: 24px;
		}
	}

	& + div {
		margin-top: 12px;
	}
`;
