import styled from "styled-components";

export const UiRuleAddPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.mainBlock {
		display: flex;
		padding-bottom: 20px;
		height: 100%;
		background-color: #fff;
		flex-direction: column;

		.wrap {
			margin: 0 auto;
			width: 100%;
			max-width: 768px;
		}

		> .stepBarBlock {
			margin: 40px 16px;
		}

		> .content {
			display: flex;
			justify-content: center;
			margin: 0 16px;
			height: 100%;
			flex-direction: column;
		}
	}

	.actionBlock {
		display: flex;
		justify-content: center;
		margin: 50px auto 0;
		width: 100%;
		max-width: 768px;
	}
`;

export const UiStepContent = styled.div`
	overflow: ${({ step }) => (step === 3 ? "unset" : "scroll")};
	height: 100%;
	min-height: ${({ step }) => (step === 3 ? "auto" : "0")};
	flex: ${({ step }) => (step === 3 ? "0" : "1 0 0")};
`;
