import styled from "styled-components";
export const UiStretchMenuLayout = styled.div`
	position: relative;
	overflow: hidden;
	height: 100vh;

	& > .header {
		display: flex;
		align-items: center;
		width: 100%;
		height: 54px;
		background-color: #3f8ae0;

		& > i {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 5px;
			padding-right: 5px;
			width: 70px;
			height: 100%;
			font-size: 24px;
			color: #fff;
			border-right: 1px solid #d6d6d6;
			cursor: pointer;
		}
	}

	& > .container {
		position: relative;
		display: flex;
		flex: 1;

		& > .mark {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			opacity: 0;
			visibility: hidden;
			z-index: 1;
			transition: 0.5s;

			&.active {
				opacity: 1;
				visibility: visible;
			}

			& > .stretchMenu {
				position: absolute;
				top: 0;
				left: -101%;
				width: 250px;
				height: calc(100vh - 54px);
				background-color: #fff;
				transition: 0.5s;

				&.active {
					left: 0;
				}

				& > .linkContainer {
					/* padding: 20px 0; */
					.ant-menu-item,
					.ant-menu-submenu-title {
						display: flex;
						align-items: center;

						i:nth-child(1) {
							margin-right: 5px;
						}
					}
				}
			}
		}

		& > .sidebar {
			position: relative;
			left: 0;
			width: 250px;
			height: calc(100vh - 54px);
			background-color: #fff;
			transition: 0.35s;

			& > .title {
				padding: 15px;
				font-size: 18px;
				color: #3f8ae0;
				background: #fff;
				margin-bottom: 7px;
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			}

			& > .version {
				position: absolute;
				bottom: 4px;
				right: 8px;
			}
		}

		& > .content {
			display: flex;
			width: 100%;
			height: calc(100vh - 54px);
			background-color: #333;
			transition: 0.35s;
			flex: 1;
			flex-direction: column;

			& > .scroll {
				position: relative;
				display: flex;
				overflow-y: auto;
				padding: 4px 20px 12px;
				height: 100%;
				flex-direction: column;
			}

			& > .footer {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 10px;
				height: 45px;
				text-align: center;
				color: #fff;
				background-color: #4a4a4a;
				box-sizing: border-box;
				letter-spacing: 2px;

				> span {
					flex: 1;
				}
			}
		}
	}
`;