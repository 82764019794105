import styled from "styled-components";
import { variant, space } from "styled-system";
import { Button as AntButton } from "antd";
export const UiButton = styled(AntButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${props => props.full ? "100%" : "max-content"};

	> .icon {
		display: flex;
		margin-right: 8px;

		> div {
			vertical-align: middle;
		}
	}
	${space}
	${variant({
  variants: {
    orange: {
      color: "#fff",
      background: "buttonColor.orange",
      borderColor: "buttonColor.orange"
    },
    deepOrange: {
      color: "#fff",
      background: "buttonColor.deepOrange",
      borderColor: "buttonColor.deepOrange"
    },
    blue: {
      color: "#fff",
      background: "buttonColor.blue",
      borderColor: "buttonColor.blue"
    },
    green: {
      color: "#fff",
      background: "buttonColor.green",
      borderColor: "buttonColor.green"
    },
    yellow: {
      color: "#fff",
      background: "buttonColor.yellow",
      borderColor: "buttonColor.yellow"
    },
    red: {
      color: "#fff",
      background: "buttonColor.red",
      borderColor: "buttonColor.red"
    },
    white: {
      color: "#535353",
      background: "buttonColor.white",
      borderColor: "#D9D9D9"
    }
  }
})}

	&:hover,
	&:focus,
	&:active {
		opacity: 0.8;
		${variant({
  variants: {
    orange: {
      color: "#fff",
      background: "buttonColor.orange",
      borderColor: "buttonColor.orange"
    },
    deepOrange: {
      color: "#fff",
      background: "buttonColor.deepOrange",
      borderColor: "buttonColor.deepOrange"
    },
    blue: {
      color: "#fff",
      background: "buttonColor.blue",
      borderColor: "buttonColor.blue"
    },
    green: {
      color: "#fff",
      background: "buttonColor.green",
      borderColor: "buttonColor.green"
    },
    yellow: {
      color: "#fff",
      background: "buttonColor.yellow",
      borderColor: "buttonColor.yellow"
    },
    red: {
      color: "#fff",
      background: "buttonColor.red",
      borderColor: "buttonColor.red"
    },
    white: {
      color: "#fff",
      background: "buttonColor.gray",
      borderColor: "buttonColor.gray"
    }
  }
})}
	}
`;