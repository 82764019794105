import React, { useState, useEffect, useMemo } from "react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { Breadcrumb, StepBar, Button } from "common-components";
import { RuleAddQuestionTypeContainer, RuleAddContent, RuleAddSourceContainer } from "components";
import {
	setSpecification,
	updateSpecificationQuestionType,
	updateSpecificationSource,
	getSpecificationSetting,
	getSpecificationQuestionType,
	getSpecificationSource,
	copySpecification,
} from "api/specification";
import queryString from "query-string";
import { useHistoryBlock } from "utils/hooks/useHistoryBlock";
import { useHistory, useLocation } from "react-router-dom";
import { UiRuleAddPage, UiStepContent } from "./RuleAddPage.style";

/**
 * 規格新增頁
 */

const breadcrumbList = [
	{
		icon: "location_searching",
		name: "規格查詢",
		path: "/",
	},
	{
		icon: "remove_red_eye",
		name: "規格設定",
		path: "/",
	},
	{
		icon: "note_add",
		name: "規格新增",
		path: "/ruleAdd",
	},
];

const steps = [
	{
		title: "新增產品",
	},
	{
		title: "選擇類型",
	},
	{
		title: "選擇出處",
	},
	{
		title: "完成",
	},
];

const initSendFormData = {
	bookIDs: "",
	education: "",
	subject: "",
	version: "",
	year: "",
	name: "",
};

export const RuleAddPage = () => {
	const initUrlParams = queryString.parse(useLocation().search);
	const history = useHistory();
	const { conditionBlock, renderBlockModal } = useHistoryBlock(history);
	const [currentStep, setCurrentStep] = useState(0);

	const [defaultSendFormData, setDefaultSendFormData] = useState(null);

	const [sendFormData, setSendFormData] = useState({});

	const [productUid, setProductUid] = useState("");

	const [questionTypeData, setQuestionTypeData] = useState([]);
	const [sendQuestionTypeData, setSendQuestionTypeData] = useState([]);

	const [sendSourceData, setSendSourceData] = useState([]);

	const [, sendSetSpecification] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await setSpecification(sendFormData);
				const { isSuccess, message, data } = result;
				if (isSuccess) {
					setCurrentStep((prevStep) => prevStep + 1);
					setProductUid(data);
				}
				return {
					isSuccess,
					message,
				};
			},
		},
	});

	const [, sendSetSpecificationQuestionType] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await updateSpecificationQuestionType({
					uid: productUid,
					attributes: sendQuestionTypeData,
				});
				const { isSuccess, message, data } = result;
				if (isSuccess) {
					setCurrentStep((prevStep) => prevStep + 1);
					setProductUid(data);
				}
				return {
					isSuccess,
					message,
				};
			},
		},
	});

	const [, sendSetSpecificationSource] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await updateSpecificationSource({
					uid: productUid,
					attributes: sendSourceData,
				});
				const { isSuccess, message, data } = result;
				if (isSuccess) {
					setCurrentStep((prevStep) => prevStep + 1);
					setProductUid(data);
				}
				return {
					isSuccess,
					message,
				};
			},
		},
	});
	//新增規格
	const [, sendSpecificationSetting] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getSpecificationSetting({
					uid: productUid,
				});
				const { isSuccess } = result;
				if (isSuccess) {
					history.push(`/ruleSearchDetail/${productUid}`);
				}
			},
		},
	});

	//新增規格
	const [, sendCopySpecification] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await copySpecification({
					originUID: initUrlParams.ruleId,
					uid: productUid,
				});
				const { isSuccess } = result;
				if (isSuccess) {
					history.push(`/ruleSearchDetail/${productUid}`);
				}
			},
		},
	});

	// checkSetting machine
	const [checkSettingData, sendCheckSettingData] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSpecificationSetting({
					uid: initUrlParams.ruleId,
				});
				const { infos } = res.data;
				return { infos };
			},
		},
	});

	const [stateGetSpecificationQuestionType, sendGetSpecificationQuestionType] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getSpecificationQuestionType(initUrlParams.ruleId);
				const {
					data: { attributes },
				} = result;
				return {
					attributes,
				};
			},
		},
	});

	const [stateGetSpecificationSource, sendGetSpecificationSource] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getSpecificationSource(initUrlParams.ruleId);
				const {
					data: { attributes },
				} = result;
				return {
					sourceAttributes: attributes,
				};
			},
		},
	});
	const { infos } = checkSettingData.context.result || {};
	const { attributes } = stateGetSpecificationQuestionType.context.result || [];
	const { sourceAttributes } = stateGetSpecificationSource.context.result || [];

	const disabledNext = useMemo(() => {
		switch (currentStep) {
			case 0:
				for (const prop in sendFormData) {
					if (sendFormData[prop] === "" || sendFormData[prop].length < 1) {
						return true;
					}
				}
				return false;
			case 1:
				if (productUid === "" || sendQuestionTypeData.length === 0) return true;
				return false;
			case 2:
				if (productUid === "" || sendSourceData.length === 0) return true;
				return false;
			default:
				return true;
		}
	}, [currentStep, sendFormData, productUid, sendQuestionTypeData, sendSourceData]);

	const onChange = (data) => {
		setSendFormData({
			...data,
		});
	};
	const currentStepHandler = (direction) => () => {
		switch (direction) {
			case "prev":
				setCurrentStep(currentStep - 1);
				break;
			case "next":
				setCurrentStep(currentStep + 1);
				break;

			default:
				break;
		}
	};

	const nextStepHandler = () => {
		switch (currentStep) {
			case 0:
				sendSetSpecification(FetchEventType.Fetch);
				break;
			case 1:
				sendSetSpecificationQuestionType(FetchEventType.Fetch);
				break;
			case 2:
				sendSetSpecificationSource(FetchEventType.Fetch);
				break;
			default:
				return true;
		}
	};
	const submitRuleAddSetting = () => {
		conditionBlock(true);
		if (initUrlParams.ruleId) {
			sendCopySpecification(FetchEventType.Fetch);
		} else {
			sendSpecificationSetting(FetchEventType.Fetch);
		}
	};

	const addQuestionTypeData = (questionTypeData) => {
		if (questionTypeData.length < 1) {
			setSendQuestionTypeData([]);
			return;
		}

		const nextData = [];
		for (const ele of questionTypeData) {
			ele.options &&
				ele.options.forEach((item) => {
					nextData.push({
						code: item.value,
						printCode: item.printCode,
					});
				});
		}
		setQuestionTypeData(questionTypeData);
		setSendQuestionTypeData(nextData);
	};

	const addSourceData = (checkedList) => {
		setSendSourceData(checkedList);
	};

	const putDefaultSendFormData = (infos) => {
		const { books, education, subject, version, year, type } = infos;
		setDefaultSendFormData({
			name: "",
			bookIDs: books,
			education,
			subject,
			version,
			year,
			type,
		});
	};

	const formatAttributes = (attributes) => {
		if (!attributes) return [];
		const attributesGroupMap = attributes.reduce((preVal, currentVal) => {
			const code = currentVal.group.code;
			if (!preVal[code]) {
				return {
					...preVal,
					[code]: {
						code: code,
						title: currentVal.group.name,
						options: [
							{
								value: currentVal.code,
								label: currentVal.name,
								printCode: currentVal.print.code,
							},
						],
					},
				};
			}
			return {
				...preVal,
				[code]: {
					code: code,
					title: currentVal.group.name,
					options: [
						...preVal[code].options,
						{
							value: currentVal.code,
							label: currentVal.name,
							printCode: currentVal.print.code,
						},
					],
				},
			};
		}, {});

		const concatData = Object.keys(attributesGroupMap).map((key) => {
			return attributesGroupMap[key];
		});
		return concatData;
	};

	useEffect(() => {
		const step1HaveEnterData =
			Object.entries(sendFormData).filter(([key, value]) => value !== "").length > 0 ? true : false;
		const checkHaveEnterData = step1HaveEnterData || sendQuestionTypeData.length > 0 || sendSourceData.length > 0;
		conditionBlock(!checkHaveEnterData);
	}, [sendFormData, sendQuestionTypeData, sendSourceData]);

	useEffect(() => {
		if (!initUrlParams.ruleId) return;
		sendCheckSettingData(FetchEventType.Fetch);
		sendGetSpecificationQuestionType(FetchEventType.Fetch);
		sendGetSpecificationSource(FetchEventType.Fetch);
	}, [initUrlParams.ruleId]);

	useEffect(() => {
		if (!infos) return;
		putDefaultSendFormData(infos);
	}, [infos]);
	useEffect(() => {
		if (!attributes) return;
		const chooseOptions = formatAttributes(attributes);
		setQuestionTypeData([...chooseOptions]);
	}, [attributes]);

	useEffect(() => {
		if (!sourceAttributes) return;
		const chooseOptions = sourceAttributes ? sourceAttributes.map((item) => item.uid) : [];
		setSendSourceData([...chooseOptions]);
	}, [sourceAttributes]);

	return (
		<UiRuleAddPage>
			<div className="breadBlock">
				<Breadcrumb data={breadcrumbList} />
			</div>
			<div className="mainBlock">
				<div className="stepBarBlock">
					<div className="wrap">
						<StepBar steps={steps} current={currentStep} />
					</div>
				</div>
				<div className="content">
					<UiStepContent step={currentStep}>
						{currentStep === 0 && <RuleAddContent.AddProduct data={defaultSendFormData} onChange={onChange} />}
						{currentStep === 1 && (
							<RuleAddQuestionTypeContainer
								uid={productUid}
								addQuestionTypeData={addQuestionTypeData}
								data={questionTypeData}
							/>
						)}
						{currentStep === 2 && (
							<RuleAddSourceContainer uid={productUid} addSourceData={addSourceData} data={sendSourceData} />
						)}
						{currentStep === 3 && <RuleAddContent.CompleteStep />}
					</UiStepContent>
					<div className="actionBlock">
						{currentStep === 0 && (
							<Button
								full={false}
								variant="white"
								mr={2}
								onClick={() => {
									setSendFormData({ ...initSendFormData });
									setDefaultSendFormData({ ...initSendFormData });
								}}>
								清除
							</Button>
						)}
						{(currentStep === 1 || currentStep === 2 || currentStep === 3) && (
							<Button full={false} variant="white" mr={2} onClick={currentStepHandler("prev")}>
								上一步
							</Button>
						)}
						{(currentStep === 0 || currentStep === 1 || currentStep === 2) && (
							<Button full={false} variant="blue" onClick={nextStepHandler} disabled={disabledNext}>
								下一步
							</Button>
						)}
						{currentStep === 3 && (
							<Button full={false} variant="blue" onClick={submitRuleAddSetting}>
								完成
							</Button>
						)}
					</div>
				</div>
			</div>
			{renderBlockModal("新增的流程尚未完成，是否要離開此頁？")}
		</UiRuleAddPage>
	);
};
