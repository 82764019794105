import styled from "styled-components";
export const UiUpload = styled.div`
	display: block;
	margin-top: 15px !important;

	& > .noFile {
		padding: 5px 0;
		color: #d6d6d6;
	}

	& > .fileList {
		display: flex;
		justify-content: space-between;
		margin: 10px 0;

		& > i {
			cursor: pointer;
		}
	}

	.ant-upload-drag-icon {
		margin-bottom: 0;

		& > i {
			font-size: 24px;
			color: #98989e;
		}
	}

	.text {
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgba(0, 0, 0, 0.45);

		& > i {
			margin-right: 5px;
			font-size: 14px;
		}
	}

	.prompt {
		color: #ff4d4f;
	}

	& > .message {
		display: flex;
		align-items: center;
		margin-top: 10px;
		color: #f00;

		& > i {
			margin-right: 5px;
			font-size: 18px;
		}
	}
`;