import styled from "styled-components";

export const UiRuleSearchChapterContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.paginationBlock {
		margin-top: 36px;
		height: 40px;
	}

	> .table {
		overflow-y: auto;
		height: 100%;
	}
`;
