import { Api } from "common-components";

/**
 * 新增題庫規格-首頁下拉選單
 */
export const getSpecificationSelection = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Specification/Add/Selection`);
	return response;
};

/**
 * 新增題庫規格-產品列表
 */
export const getSpecificationProduct = async (year) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Specification/Product?year=${year}`);
	return response;
};

/**
 * get 規格設定-
 */
export const getSpecificationSetting = async ({ type, uid }) => {
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/Specification/SettingTabs${type ? "/" + type : ""}?UID=${uid}`,
	);
	return response;
};

/**
 * update 檢核設定
 */
export const updateSetting = async (type, payload) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Specification/SettingTabs/${type}`, payload);
	return response;
};

/**
 * update 規格基本資料
 */
export const updateBasicSetting = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Specification/Basic`, payload);
	return response;
};

/**
 * get 規格查詢 QuestionType
 */
export const getSpecificationQuestionType = async (uid) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Specification/QuestionType?UID=${uid}`);
	return response;
};

/**
 * update 規格查詢 QuestionType
 */
export const updateSpecificationQuestionType = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Specification/QuestionType`, payload);
	return response;
};

/**
 * get 規格查詢 範圍表
 */
export const getSpecificationChapters = async (uid) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Specification/Chapters?UID=${uid}`);
	return response;
};

/**
 * get 規格查詢 類別表
 */
export const getSpecificationCategory = async (uid) => {
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/Specification/Metas?UID=${uid}&metaType=category`,
	);
	return response;
};

/**
 * 新增題庫規格-首頁下拉選單
 */
export const setSpecification = async (params) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Specification/Add`, params);
	return response;
};

/**
 * 查詢規格出處表
 */
export const getSpecificationSource = async (uid) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Specification/Source?UID=${uid}`);
	return response;
};
/**
 * 新增規格出處表
 */
export const updateSpecificationSource = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Specification/Source`, payload);
	return response;
};

/**
 * 新增題庫規格-試題入庫篩選
 */
export const getSpecificationProductFilter = async (year) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Specification/Product/Filter?year=${year}`);
	return response;
};

/**
 * 複製題庫規格
 */
export const copySpecification = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Specification/SettingTabs`, payload);
	return response;
};
