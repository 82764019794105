import styled from "styled-components";
export const UiPickListDropdown = styled.div`
	position: relative;
	display: inline-block;
	user-select: none;

	> .header {
		display: flex;
		justify-content: space-between;
		padding: 6px 12px;
		min-width: 120px;
		color: #fff;
		background-color: #626262;
		cursor: pointer;

		&:hover {
			background-color: rgba(255, 255, 255, 0.06);
		}

		> .icon {
			margin-left: 10px;
			font-size: 0;

			> div {
				cursor: pointer;

				> * {
					font-size: 24px;
				}
			}
		}

		> .title {
			display: flex;
			align-items: center;
		}

		.others {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 4px;
			width: 19px;
			height: 19px;
			color: #fff;
			background-color: #ff8933;
			border-radius: 50%;
		}
	}

	> .content {
		position: absolute;
		right: 0;
		margin-top: 4px;
		padding: 8px;
		width: max-content;
		min-width: 100%;
		max-width: 400px;
		background-color: #fff;
		z-index: 1;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

		> .action {
			display: flex;
			justify-content: flex-end;
			border-top: 1px solid #f0f0f0;
		}

		> .optionsWrap {
			overflow-x: hidden;
			max-height: 330px;

			.tabCheckCount {
				margin-left: 4px;
				padding: 4px 8px;
				color: #fff;
				background-color: #79bfff;
				border-radius: 50%;
			}

			> .ant-checkbox-group {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.tabHeader {
		> .ant-checkbox-wrapper {
			margin-right: 4px;
		}
	}
`;
export const UiCheckList = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 32px;

	&:hover {
		background-color: #dfeeff;
	}

	> .ant-checkbox-wrapper {
		display: flex;
		align-items: center;
		padding: 0 12px;
		width: 100%;
		height: 100%;

		.ant-checkbox {
			top: auto;
		}
	}
`;
export const UiClearButton = styled.button`
	display: flex;
	margin: 8px 4px 4px 0;
	padding: 1px 8px;
	background-color: transparent;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: none;
	transition: 0.3s;
	cursor: pointer;

	> .icon {
		margin-right: 10px;
	}

	&:hover {
		background-color: #dfeeff;
	}

	&:disabled,
	&[disabled] {
		cursor: auto;
		color: #ccc;
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}
`;
export const SingleList = styled.div`
	display: flex;
	align-items: center;
	padding: 0 12px;
	width: 100%;
	height: 100%;

	&:hover {
		background-color: #dfeeff;
	}
`;