import styled from "styled-components";
import { space } from "styled-system";
export const UiCheckboxer = styled.div`
	display: flex;
	align-items: center;
	${space}
	> .label {
		margin-right: 8px;
		flex: 0 0 57px;
		font-size: 14px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.65);
	}

	.group {
		> .ant-checkbox-group {
			display: flex;
			flex-wrap: wrap;
			max-width: max-content;

			> .option {
				color: rgba(0, 0, 0, 0.65);
			}
		}

		> .ant-radio-group {
			display: flex;
			flex-wrap: wrap;
			max-width: max-content;

			> .option {
				color: rgba(0, 0, 0, 0.65);
			}
		}
	}
`;