import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "common-components";
export const useHistoryBlock = (history) => {
	const [pathName, setPathName] = useState("");
	const [showModal, setShowModal] = useState(false);
	const location = useLocation();
	//data沒變condition = true
	const conditionBlock = (condition) => {
		history.block(({ pathname }) => {
			if (condition) return true;
			setShowModal(location.pathname === pathname ? false : true);
			setPathName(pathname);
			return false;
		});
	};
	const leavePage = () => {
		history.push(`${pathName}`);
	};
	const renderBlockModal = (message) => {
		return (
			<Modal.WarningModal
				message={message}
				visible={showModal}
				onOk={() => {
					conditionBlock(true);
					leavePage();
				}}
				onCancel={() => {
					setShowModal(false);
				}}
			/>
		);
	};
	return { conditionBlock, renderBlockModal };
};
