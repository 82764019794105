import styled from "styled-components";
export const UiInput = styled.div`
	display: ${({
  row
}) => row ? "flex" : "block"};
	align-items: center;
	width: 100%;
	font-size: 15px;

	& > .ant-input-affix-wrapper {
		width: 100%;
		flex: 1 1 auto;
	}

	.label {
		margin-right: ${({
  row
}) => row ? "15px" : "0"};
		color: rgba(0, 0, 0, 0.65);
		margin-bottom: ${({
  row
}) => row ? "0" : "1px"};
		flex: 0 0 ${({
  row
}) => row ? "60px" : "auto"};
		word-break: break-all;
	}

	.message {
		display: flex;
		align-items: center;
		margin-top: 5px;
		font-size: 12px;
		color: #ff4d4f;

		& > i {
			margin-right: 5px;
		}
	}

	&.dark {
		.ant-input-prefix,
		.ant-input-suffix {
			.material-icons {
				font-size: 18px;
				color: #888;
			}
		}

		.ant-input-affix-wrapper {
			background-color: #626262;
			border-color: #626262;
		}

		.ant-input {
			color: #fff;
			background-color: #626262;
			border-color: #626262;

			&::placeholder {
				color: rgba(255, 255, 255, 0.5);
			}

			input::placeholder {
				color: rgba(255, 255, 255, 0.5);
			}
		}

		.ant-calendar-picker-clear {
			background-color: transparent;
		}

		.ant-input-search-icon {
			color: rgba(255, 255, 255, 0.5);
		}
	}
`;