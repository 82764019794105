import styled from "styled-components";
export const UiAddList = styled.div`
	display: block;
	width: 100%;
	max-width: 300px;

	.title {
		display: flex;
		justify-content: space-between;
		padding: 16px 10px;
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		background-color: #fafafa;
		border-bottom: 1px solid #f0f0f0;
	}

	.item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		width: 100%;
		font-size: 14px;
		border-bottom: 1px solid #f0f0f0;

		i {
			margin-left: 10px;
			color: #d9d9d9;
			cursor: pointer;
			user-select: none;
			transition: 0.35s;

			&:hover {
				color: #686868;
			}
		}
	}

	.input {
		display: flex;
		padding: 10px 10px 10px 0;

		.icon i {
			margin-top: 3px;
			margin-left: 10px;
			color: #686868;
			border-radius: 50%;
			transition: 0.35s;
			cursor: pointer;
			user-select: none;

			&:hover {
				background-color: #d9d9d9;
			}
		}
	}
`;