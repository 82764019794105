import styled from "styled-components";
import { variant } from "styled-system";
export const UiSelect = styled.div`
	display: ${({
  row
}) => row ? "flex" : "block"};
	align-items: center;
	width: 100%;
	min-width: 120px;
	font-size: 15px;

	& > .ant-select {
		width: 100%;
		flex: 1 1 auto;

		> .ant-select-selector {
			${variant({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262",
      boxShadow: "inset -1px 0 0 rgba(255, 255, 255, 0.25)"
    }
  }
})}
		}

		> .ant-select-arrow {
			${variant({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
})}
		}

		> .ant-select-clear {
			${variant({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
})}
		}
	}

	.label {
		margin-right: ${({
  row
}) => row ? "15px" : "0"};
		color: rgba(0, 0, 0, 0.65);
		margin-bottom: ${({
  row
}) => row ? "0" : "1px"};
		flex: 0 0 ${({
  row
}) => row ? "60px" : "auto"};
		word-break: break-all;
	}

	.message {
		display: flex;
		align-items: center;
		margin-top: 5px;
		font-size: 12px;
		color: #ff4d4f;

		& > i {
			margin-right: 5px;
		}
	}
`;