import styled from "styled-components";
import { space } from "styled-system";
export const UiIcon = styled.div`
	display: inline-flex;
	transform: ${({
  rotate
}) => `rotate(${rotate}deg) `};
	color: ${({
  color,
  focus,
  focuscolor
}) => (focus ? focuscolor : color) || "currentColor"};
	background-color: ${({
  bgcolor
}) => bgcolor || "unset"};
	border-radius: 4px;
	cursor: ${({
  disabled,
  clickable
}) => disabled ? "not-allowed" : clickable ? "pointer" : "auto"};
	${space}
`;
export const UiIconPop = styled.div`
	display: inline-flex;

	.ant-popover-message {
		display: flex;
		align-items: center;

		.ant-popover-message-title {
			padding-left: 0;
		}
	}
`;