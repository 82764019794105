import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import QuestionTypeContent from "../QuestionTypeContent";
import { UiRuleSearchQuestionTypeContainer } from "./RuleSearchQuestionTypeContainer.style";

import { getSpecificationQuestionType, updateSpecificationQuestionType } from "api/specification";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { FetchStates } from "machines/fetchMachine/index";

/**
 * 規格查詢 題型表 container
 */

export const RuleSearchQuestionTypeContainer = () => {
	const [chooseQuestionTypeOptions, setChooseQuestionTypeOptions] = useState([]);
	const [sendQuestionTypeData, setSendQuestionTypeData] = useState([]);
	const { id: uid } = useParams();

	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getSpecificationQuestionType(uid);
				const {
					data: {
						attributes,
						attributeMap,
						selections: { QUES_GROUP: quesGroup },
					},
				} = result;
				let questionTypeOptions = [];
				if (quesGroup) {
					for (const prop in quesGroup) {
						if (!attributeMap[quesGroup[prop].code]) continue;
						questionTypeOptions = [
							...questionTypeOptions,
							{
								title: quesGroup[prop].name,
								code: quesGroup[prop].code,
								options: Object.entries(attributeMap[quesGroup[prop].code]).map(([key, value]) => ({
									label: value.name,
									value: value.code,
									printCode: value.print.code,
								})),
							},
						];
					}
				}

				return {
					attributes,
					questionTypeOptions,
				};
			},
			updateData: (_context, event) => {
				return updateSpecificationQuestionType({ uid: uid, attributes: event.payload.attributes });
			},
		},
	});

	const formatAttributes = (attributes) => {
		if (!attributes) return [];
		const attributesGroupMap = attributes.reduce((preVal, currentVal) => {
			const code = currentVal.group.code;
			if (!preVal[code]) {
				return {
					...preVal,
					[code]: {
						code: code,
						title: currentVal.group.name,
						options: [
							{
								value: currentVal.code,
								label: currentVal.name,
								printCode: currentVal.print.code,
							},
						],
					},
				};
			}
			return {
				...preVal,
				[code]: {
					code: code,
					title: currentVal.group.name,
					options: [
						...preVal[code].options,
						{
							value: currentVal.code,
							label: currentVal.name,
							printCode: currentVal.print.code,
						},
					],
				},
			};
		}, {});

		const concatData = Object.keys(attributesGroupMap).map((key) => {
			return attributesGroupMap[key];
		});
		return concatData;
	};

	const { attributes, questionTypeOptions } = state.context.result || [];
	const defaultChoose = useMemo(() => {
		if (!state.matches(FetchStates.Resolved)) return [];
		const chooseOptions = formatAttributes(attributes);
		setChooseQuestionTypeOptions([...chooseOptions]);
		return chooseOptions;
	}, [state.value]);

	const clearData = () => {
		setChooseQuestionTypeOptions([]);
	};

	const findCodeData = (code) => {
		let result = [];
		for (var i = 0; i < questionTypeOptions.length; i++) {
			if (code === questionTypeOptions[i].code) {
				result = questionTypeOptions[i].options;
				break;
			}
		}
		return result;
	};

	const addData = (title, code, val) => {
		const nextData = chooseQuestionTypeOptions;
		const codeData = findCodeData(code);
		let isExist = false;
		for (var i = 0; i < nextData.length; i++) {
			if (nextData[i].code === code) {
				isExist = true;
				if (val.length < 1) {
					nextData.splice(i, 1);
				} else {
					nextData[i] = {
						title,
						code,
						options: val.map((item) => codeData.find((ele) => ele.value === item)),
					};
				}

				break;
			}
		}
		if (isExist) {
			setChooseQuestionTypeOptions([...nextData]);
		} else {
			setChooseQuestionTypeOptions([
				...chooseQuestionTypeOptions,
				{
					title,
					code,
					options: val.map((item) => codeData.find((ele) => ele.value === item)),
				},
			]);
		}
	};

	const addQuestionTypeData = (questionTypeData) => {
		if (questionTypeData.length < 1) {
			setSendQuestionTypeData([]);
			return;
		}

		const nextData = [];
		for (const ele of questionTypeData) {
			ele.options &&
				ele.options.forEach((item) => {
					nextData.push({
						code: item.value,
						printCode: item.printCode,
					});
				});
		}
		setSendQuestionTypeData(nextData);
	};

	const saveOptionsHandler = (data) => {
		send(FetchEventType.Update, {
			payload: {
				attributes: data,
			},
		});
	};

	const cancelOptionsHandler = (defaultData) => {
		setChooseQuestionTypeOptions([...defaultData]);
	};

	useEffect(() => {
		if (!uid) return;

		send(FetchEventType.Fetch);
	}, [uid]);

	useEffect(() => {
		addQuestionTypeData && addQuestionTypeData(chooseQuestionTypeOptions);
	}, [chooseQuestionTypeOptions]);

	return (
		<UiRuleSearchQuestionTypeContainer>
			<QuestionTypeContent
				questionTypeOptions={questionTypeOptions}
				chooseQuestionTypeOptions={chooseQuestionTypeOptions}
				clearData={clearData}
				addData={addData}
				saveOptionsHandler={() => saveOptionsHandler(sendQuestionTypeData)}
				cancelOptionsHandler={() => cancelOptionsHandler(defaultChoose)}
				mode="ruleSearch"
			/>
		</UiRuleSearchQuestionTypeContainer>
	);
};
