import styled from "styled-components";

export const UiMainLayoutHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	color: #fff;
	flex: 1;

	.title {
		margin: 0;
		font-size: 24px;
		color: #fff;
		cursor: pointer;
	}
`;

export const UiMenu = styled.div`
	.ant-menu-item,
	.ant-menu-submenu-title {
		display: flex;
		align-items: center;

		i:nth-child(1) {
			margin-right: 5px;
		}

		.ant-menu-item-icon {
			margin-right: 5px;
			font-size: 18px;
		}

		.ant-menu-title-content {
			margin-left: 0;
		}
	}
`;

export const UiIconTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 0 5px;
	font-size: 24px;

	i {
		margin-right: 5px;
		font-size: 28px;
	}

	.title {
		display: flex;
		align-items: baseline;
	}

	.version {
		margin-left: 4px;
		font-size: 14px;
	}
`;

export const UiUserBox = styled.div`
	display: flex;
	align-items: center;
	transition: 0.5s;

	img {
		overflow: hidden;
		margin-right: 5px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	&:hover {
		color: #fff;
		opacity: 0.8;
	}
`;
