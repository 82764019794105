import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Table, Pagination } from "common-components";
import { sliceTableData } from "../../utils/common";
import { UiRuleSearchChapterContainer } from "./RuleSearchChapterContainer.style";

import { getSpecificationChapters } from "api/specification";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";

/**
 * 範圍表 container
 */

export const RuleSearchChapterContainer = () => {
	const { id: uid } = useParams();
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});

	useEffect(() => {
		sendChapters(FetchEventType.Fetch, {});
	}, []);

	// chapters machine
	const [chaptersData, sendChapters] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSpecificationChapters(uid);
				const data = res.data;
				const flatData = Object.keys(data).map((key) => {
					return data[key]
						? data[key].chapters.map((ele) => ({
								...ele,
								bookName: data[key].bookInfo.bookName,
						  }))
						: [];
				});
				const concatData = [].concat.apply([], flatData);
				return concatData;
			},
		},
	});
	const bookChaptersInfo = chaptersData.context.result || [];
	const renderKnowledgeList = (knowledgeList) => (
		<div>
			{knowledgeList &&
				knowledgeList.map((item) => {
					const itemInfo = item.name.split(" ");
					const itemCode = itemInfo[0];
					const itemName = itemInfo[1];
					return (
						<div key={item.code}>
							<span>{itemCode}</span> | <span className="knowledgeName">{itemName}</span>
						</div>
					);
				})}
		</div>
	);

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
	};

	return (
		<UiRuleSearchChapterContainer>
			<div className="table">
				<Table variant="light">
					<Table.Header>
						<Table.Row>
							<Table.Item>冊次</Table.Item>
							<Table.Item>代碼</Table.Item>
							<Table.Item flex={5}>名稱</Table.Item>
							<Table.Item>二段範圍</Table.Item>
							<Table.Item>三段範圍</Table.Item>
							<Table.Item flex={4}>向度</Table.Item>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{bookChaptersInfo &&
							sliceTableData(bookChaptersInfo, pageState.currentPage, pageState.limit).map((item, index) => {
								return (
									<Table.Row key={index}>
										<Table.Item>{item.bookName}</Table.Item>
										<Table.Item>{item.code}</Table.Item>
										<Table.Item flex={5}>{item.name}</Table.Item>
										<Table.Item>{item.examType2?.name}</Table.Item>
										<Table.Item>{item.examType3?.name}</Table.Item>
										<Table.Item flex={4}>{renderKnowledgeList(item.knowledgeList)}</Table.Item>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</div>
			<div className="paginationBlock">
				<Pagination
					variant="white"
					total={bookChaptersInfo?.length || 0}
					defaultCurrent={1}
					current={pageState.currentPage}
					onChange={pageChange}
					defaultPageSize={pageState.limit}
				/>
			</div>
		</UiRuleSearchChapterContainer>
	);
};

RuleSearchChapterContainer.propTypes = {};
