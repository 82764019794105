import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import SourceContent from "../SourceContent";
import { UiRuleSearchSourceContainer } from "./RuleSearchSourceContainer.style";

import { getSpecificationSource, updateSpecificationSource } from "api/specification";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { FetchStates } from "machines/fetchMachine/index";

/**
 * 規格查詢 出處表 container
 */

export const RuleSearchSourceContainer = () => {
	const [checkedList, setCheckedList] = useState([]);
	const [sendSourceData, setSendSourceData] = useState([]);
	const { id: uid } = useParams();

	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getSpecificationSource(uid);
				const {
					data: { attributeMap, attributes },
				} = result;
				return {
					sourceOptions:
						attributeMap?.SOURCE.map((item) => ({
							label: item.name,
							value: item.uid,
						})) || [],
					attributes,
				};
			},
			updateData: (_context, event) => {
				return updateSpecificationSource({ uid: uid, attributes: event.payload.attributes });
			},
		},
	});

	const { sourceOptions, attributes } = state.context.result || [];

	const chooseSourceOptions = useMemo(() => {
		if (!sourceOptions) return [];
		let nextData = [];
		for (const item of checkedList) {
			const findData = sourceOptions.find((ele) => ele.value === item);
			if (findData) nextData = [...nextData, findData];
		}
		return nextData;
	}, [checkedList, sourceOptions]);

	const defaultChoose = useMemo(() => {
		if (!state.matches(FetchStates.Resolved)) return [];
		const chooseOptions = attributes ? attributes.map((item) => item.uid) : [];
		setCheckedList([...chooseOptions]);
		return chooseOptions;
	}, [state.value]);

	const addData = (val) => {
		setCheckedList(val);
	};

	const clearData = () => {
		setCheckedList([]);
	};

	const addSourceData = (checkedList) => {
		setSendSourceData(checkedList);
	};

	const saveOptionsHandler = (data) => {
		send(FetchEventType.Update, {
			payload: {
				attributes: data,
			},
		});
	};

	const cancelOptionsHandler = (defaultData) => {
		setCheckedList([...defaultData]);
	};

	useEffect(() => {
		if (!uid) return;
		send(FetchEventType.Fetch);
	}, [uid]);

	useEffect(() => {
		if (!checkedList) return;
		addSourceData && addSourceData(checkedList);
	}, [checkedList]);

	return (
		<UiRuleSearchSourceContainer>
			<SourceContent
				sourceOptions={sourceOptions}
				chooseSourceOptions={chooseSourceOptions}
				checkedList={checkedList}
				clearData={clearData}
				addData={addData}
				saveOptionsHandler={() => saveOptionsHandler(sendSourceData)}
				cancelOptionsHandler={() => cancelOptionsHandler(defaultChoose)}
				mode="ruleSearch"
			/>
		</UiRuleSearchSourceContainer>
	);
};

RuleSearchSourceContainer.propTypes = {};
