import styled from "styled-components";

export const UiHomePage = styled.div`
	position: relative;
	display: block;

	.flexStartBox {
		display: flex;
		padding: 10px 0;
	}

	.flexEndBox {
		display: flex;
		justify-content: flex-end;
		padding: 10px 0;
	}

	.ruleChoice {
		background-color: red;
	}
`;

export const BtnGroup = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	& > *:not(:last-child) {
		margin-right: 8px;
	}

	& > * {
		margin: 4px 0;
		min-width: 80px;
	}
`;

export const UiPopupContainer = styled.div`
	.ant-tabs-tab-btn {
		display: flex;
		align-items: center;

		i {
			margin-right: 5px;
		}
	}

	.ant-image-preview-img-wrapper::before {
		height: 25%;
	}

	.ant-image-preview-img {
		margin: auto;
		width: 100%;
		max-width: 660px;
	}

	.container {
		position: relative;
		display: flex;
		justify-content: space-between;
		overflow-x: hidden;
		margin-top: 20px;
		max-height: 600px;

		&::after {
			position: absolute;
			top: 0;
			right: calc(50% - 2px);
			width: 3px;
			height: 100%;
			background-color: #f0f0f0;
			content: "";
		}

		> .content {
			position: relative;
			display: flex;
			justify-content: space-between;
			width: 45%;
			flex-direction: column;

			> .meta {
				padding: 2px 0;
				color: rgba(0, 0, 0, 0.85);

				> .metaContent {
					padding: 0 5px;
					color: rgba(0, 0, 0, 0.65);
				}
			}

			> .title {
				position: sticky;
				top: 0;
				padding: 10px;
				font-size: 16px;
				background-color: #dfeeff;
				margin-bottom: 10px;
			}

			.img {
				display: block;
				font-size: 0;
				flex: 1;
			}

			.info {
				margin-top: 10px;
				padding: 5px 0;
				background-color: #fafafa;

				li {
					font-size: 14px;
				}
			}
		}

		.bookTag {
			display: flex;
			align-items: center;

			& > div {
				display: flex;
				margin-bottom: 8px;

				& > div {
					margin-right: 8px;
				}
			}
		}
	}
`;

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;

export const UiRuleModalContent = styled.div`
	display: block;

	& > .ruleDetail {
		display: flex;
		width: 100%;

		& > div + div {
			margin: 0 0 8px 8px;
		}
	}
`;

export const UiLink = styled.div`
	display: flex;
	align-items: center;
	text-decoration: underline;
	color: #82b4ff;
	cursor: pointer;
	text-underline-offset: 2px;
`;

export const UiStatus = styled.div`
	&.error {
		color: #ff4d4f;
	}

	&.loading {
		color: #98989e;
	}
`;

export const UiQuesDetail = styled.div`
	.ant-modal-body {
		padding: 6px 40px;
	}
`;
