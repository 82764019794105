import { Api } from "common-components";

/**
 * 查詢 課本章節資料
 */
export const getChapterBooks = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}`);
	return response;
};

/**
 * 查詢 課本章節資料頁 使用之下拉選單
 */
export const getChapterBooksListSelection = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}/Selection`);
	return response;
};

/**
 * 查詢 新增課本章節資料 使用之相關資訊
 */
export const getChapterBooksRelated = async (year, payload) => {
	const response = await Api.get(
		`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${year}/Books/Related`,
		payload,
	);
	return response;
};

/**
 * 取得新增節次相關資料
 */
export const getBooksRelated = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}/Related`);
	return response;
};

/**
 * 新增節次
 */
export const createChapterBook = async (bookID, payload) => {
	const response = await Api.post(`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}`, payload);
	return response;
};

/**
 * 修改節次
 */
export const editChapterBook = async (bookID, payload) => {
	const response = await Api.put(
		`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}/Section`,
		payload,
	);
	return response;
};

/**
 * 取得修改章節相關資料
 */
export const getChapterSectionRelated = async (bookID, chapterUID) => {
	const response = await Api.get(
		`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}/Related/${chapterUID}`,
	);
	return response;
};

/**
 * 刪除章節
 */
export const deleteChapterSection = async (bookID, payload) => {
	const { ChapterUID } = payload;
	const response = await Api.delete(
		`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}/Section?chapterUID=${ChapterUID}`,
	);
	return response;
};

/**
 * 上傳章節
 */
export const importChapter = (bookID) => async (payload) => {
	const response = await Api.post(
		`${process.env.REACT_APP_RESOURCE_LIBRARY_DOMAIN}/api/Chapter/${bookID}/Import`,
		payload,
	);
	return response;
};
