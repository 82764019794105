import styled, { css } from "styled-components";
import { flexbox, space, variant } from "styled-system";
export const UiTableItem = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	padding: 4px 15px;
	min-width: 100px;
	text-overflow: ellipsis;
	${({
  issticky
}) => issticky && css`
			position: sticky;
			left: 0;
		`}
	${flexbox}
`;
export const UiTableRow = styled.div`
	display: flex;
	width: 100%;
	flex-grow: 0;
	flex-direction: row;
	flex-wrap: nowrap;
	${({
  issticky
}) => issticky && css`
			position: sticky;
			top: 0;
			z-index: 2;
		`}
`;
export const UiTableHeader = styled.div`
	display: flex;

	${UiTableItem} {
		font-size: 14px;
		font-weight: 700;
	}
`;
export const UiTableBody = styled.div`
	position: relative;
	flex: 1;

	${UiTableItem} {
		font-size: 14px;
		font-weight: 400;
	}
`;
export const UiTable = styled.div`
	display: flex;
	overflow: auto;
	height: 100%;
	flex: 1;
	flex-direction: column;
	${space}
	${variant({
  variants: {
    default: {
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
    }
  }
})}
	${UiTableHeader} {
		${UiTableItem} {
			${variant({
  variants: {
    default: {
      backgroundColor: "lightblue",
      color: "white"
    },
    light: {
      backgroundColor: "#fafafa",
      color: "#000"
    }
  }
})}
		}
		${UiTableRow} {
			${variant({
  variants: {
    light: {
      color: "#000",
      borderBottom: "1px solid #f0f0f0"
    }
  }
})}
		}
	}
	${UiTableBody} {
		${UiTableRow} {
			${variant({
  variants: {
    default: {
      color: "white"
    },
    light: {
      color: "#000",
      borderBottom: "1px solid #f0f0f0"
    }
  }
})}

			&:nth-child(odd) > div {
				${variant({
  variants: {
    default: {
      backgroundColor: "dark"
    }
  }
})}
			}

			&:nth-child(even) > div {
				${variant({
  variants: {
    default: {
      backgroundColor: "gray"
    }
  }
})}
			}

			&:first-child > div {
				${({
  issticky
}) => issticky && css`
						color: ${({
  theme
}) => theme.colors.white};
						background: ${({
  theme
}) => theme.colors.lightblue};
					`}
			}

			& + ${UiTableRow} > ${UiTableItem} {
				${variant({
  variants: {
    default: {
      borderBottom: "1px solid rgba(255, 255, 255, 0.2)"
    }
  }
})}
			}
		}
	}
`;