import styled from "styled-components";
export const UiLeftSidebarLayout = styled.div`
	position: relative;
	overflow: hidden;
	height: 100vh;

	> .header {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		width: 100%;
		height: 54px;
		background-color: #3f8ae0;

		& > i {
			margin-right: 5px;
			padding-right: 5px;
			color: #fff;
			border-right: 1px solid #fff;
			cursor: pointer;
		}
	}

	> .container {
		position: relative;
		display: flex;
		flex: 1;

		.sidebar {
			position: relative;
			left: 0;
			display: flex;
			justify-content: space-between;

			width: 15%;
			height: calc(100vh - 54px);
			background-color: #fff;
			transition: 0.35s;
			flex-direction: column;

			& > .version {
				position: absolute;
				bottom: 49px;
				right: 8px;
			}

			&.noActive {
				position: absolute;
				top: 0;
				left: -250px;
			}

			.action {
				display: flex;
				justify-content: flex-end;
				padding: 10px;
				width: 100%;
				height: 45px;
				border-top: 1px solid rgba(0, 0, 0, 0.1);

				& > i {
					border-radius: 99px;
					transition: 0.35s;
					cursor: pointer;

					&:hover {
						color: #fff;
						background-color: rgba(0, 0, 0, 0.2);
					}
				}
			}
		}

		> .content {
			display: flex;

			width: 85%;
			height: calc(100vh - 54px);
			background-color: #333;
			transition: 0.35s;

			flex-direction: column;

			.scroll {
				position: relative;
				display: flex;
				overflow: auto;
				padding: 20px;
				height: 100%;
				flex-direction: column;
			}

			.footer {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 10px;
				min-height: 45px;
				text-align: center;
				color: #fff;
				background-color: #4a4a4a;
				box-sizing: border-box;
				letter-spacing: 2px;

				> span {
					flex: 1;
				}
			}
		}
	}
`;