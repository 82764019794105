import { createMachine, assign } from "xstate";
import { logoutNaniOneClass, checkNaniLinkLogin } from "../../services/oneClub";
import { setCookie, deleteAllCookies, checkLogin } from "../../utils/common";
import { loginByOneClub, loginGetUserProfile, setUserProfile } from "../../api/login";
import { getUserAuthority } from "../../api/authority";
import { COOKIE_LOGIN_TOKEN_NAME } from "../../constants";
export const AuthStates = {
  Idle: "Idle",
  Loading: "Loading",
  LoginDone: "LoginDone",
  ReadySettingEducation: "ReadySettingEducation",
  SettingEducation: "SettingEducation",
  getAuthority: "getAuthority",
  UpdateUserProfile: "UpdateUserProfile"
};
export const AuthEventType = {
  Logging: "Logging",
  LoggingOut: "LoggingOut",
  LoggingEnd: "LoggingEnd",
  SetEducation: "SetEducation",
  UpdatingUserProfile: "UpdatingUserProfile"
};
const init = {
  identity: "",
  name: "",
  eduMap: [],
  subject: "",
  education: [],
  account: ""
};
export const authMachine = createMachine({
  id: "loginMachine",
  initial: checkNaniLinkLogin() !== "" ? AuthStates.Loading : AuthStates.Idle,
  context: {
    result: { ...init
    },
    apiBaseUrl: "",
    productApiUrl: "",
    authority: {},
    error: null,
    loading: false
  },
  states: {
    [AuthStates.Idle]: {
      entry: assign({
        result: () => {
          deleteAllCookies();
          return { ...init
          };
        },
        loading: () => false
      }),
      on: {
        [AuthEventType.Logging]: AuthStates.Loading
      }
    },
    [AuthStates.Loading]: {
      invoke: {
        src: async (context, event) => {
          const resourcePlatformToken = checkLogin(COOKIE_LOGIN_TOKEN_NAME);

          if (resourcePlatformToken) {
            const profile = await loginGetUserProfile({
              url: context.apiBaseUrl
            });

            if (!profile?.isSuccess) {
              return Promise.reject(profile);
            }
          } else {
            const naniToken = checkNaniLinkLogin() || "";
            const result = await loginByOneClub({
              url: context.apiBaseUrl,
              jwt: naniToken ? JSON.parse(naniToken).jwt : JSON.parse(event.jwt).jwt
            });
            const {
              data
            } = result;

            if (typeof data === "string") {
              //非首次登入
              setCookie(COOKIE_LOGIN_TOKEN_NAME, data, 1);
              const profile = await loginGetUserProfile({
                url: context.apiBaseUrl
              });

              if (profile.isSuccess) {
                return {
                  token: data,
                  ...profile.data
                };
              } else {
                return Promise.reject(profile);
              }
            } else {
              const {
                token
              } = data;
              setCookie(COOKIE_LOGIN_TOKEN_NAME, token, 1);
              return data;
            }
          }
        },
        onDone: [{
          target: AuthStates.ReadySettingEducation,
          cond: (_, event) => {
            return event?.data?.isNewMember ? true : false;
          },
          actions: assign({
            result: (_context, event) => {
              return event.data;
            },
            error: () => null,
            loading: () => true
          })
        }, {
          target: AuthStates.getAuthority,
          actions: assign({
            result: (_context, event) => {
              return event.data;
            },
            error: () => null,
            loading: () => true
          })
        }],
        onError: {
          target: AuthStates.Idle,
          actions: assign({
            result: _context => {
              logoutNaniOneClass();
              return { ...init
              };
            },
            error: (_, event) => {
              const newMessage = event?.data?.data?.message || "您的帳號已重複登入！";
              return newMessage;
            }
          })
        }
      }
    },
    [AuthStates.ReadySettingEducation]: {
      on: {
        [AuthEventType.SetEducation]: {
          target: AuthStates.SettingEducation
        },
        [AuthEventType.LoggingOut]: {
          target: AuthStates.Idle
        }
      }
    },
    [AuthStates.SettingEducation]: {
      invoke: {
        id: "SettingEducation",
        src: async (context, event) => {
          const result = await setUserProfile({
            url: context.apiBaseUrl,
            payload: {
              education: [event.chooseEdu]
            }
          });
          const {
            isSuccess,
            data
          } = result;

          if (!isSuccess) {
            throw new {
              error: "error"
            }();
          }

          return data;
        },
        onDone: {
          target: AuthStates.getAuthority,
          actions: assign({
            result: (_context, event) => {
              return { ...event.data,
                isNewMember: _context.result.isNewMember
              };
            },
            error: () => null,
            loading: () => true
          })
        },
        onError: {
          target: AuthStates.Idle,
          actions: assign({
            result: (_context, event) => {
              logoutNaniOneClass();
              return { ...init
              };
            },
            error: event => {
              return event.error;
            }
          })
        }
      }
    },
    [AuthStates.getAuthority]: {
      invoke: {
        src: async context => {
          const {
            isSuccess,
            data
          } = await getUserAuthority(context.productApiUrl);

          if (!isSuccess) {
            return Promise.reject(data);
          }

          return {
            authority: data?.authority || [],
            data
          };
        },
        onDone: {
          target: AuthStates.LoginDone,
          actions: assign({
            authority: (_, event) => {
              return event.data.authority;
            },
            result: (_context, event) => {
              return { ..._context.result,
                ...event.data.data
              };
            }
          })
        },
        onError: {
          target: AuthStates.Idle,
          actions: assign({
            result: (_context, event) => {
              logoutNaniOneClass();
              return { ...init
              };
            },
            error: (_, event) => {
              return event.data;
            }
          })
        }
      }
    },
    [AuthStates.UpdateUserProfile]: {
      invoke: {
        src: async (context, event) => {
          const {
            isSuccess,
            data
          } = await getUserAuthority(context.productApiUrl);

          if (!isSuccess) {
            return Promise.reject(data);
          }

          return data;
        },
        onDone: {
          target: AuthStates.LoginDone,
          actions: assign({
            result: (_context, event) => {
              return { ..._context.result,
                ...event.data
              };
            }
          })
        },
        onError: {
          target: AuthStates.Idle,
          actions: assign({
            result: (_context, event) => {
              logoutNaniOneClass();
              return { ...init
              };
            },
            error: event => {
              return event.error;
            }
          })
        }
      }
    },
    [AuthStates.LoginDone]: {
      on: {
        [AuthEventType.LoggingOut]: {
          target: AuthStates.Idle
        },
        [AuthEventType.UpdatingUserProfile]: {
          target: AuthStates.UpdateUserProfile
        }
      }
    }
  }
});
export default authMachine;