import styled from "styled-components";

export const UiRuleSearchDetailPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.mainBlock {
		display: flex;
		padding: 0 18px;
		padding-bottom: 20px;
		height: 100%;
		background-color: #fff;
		flex-direction: column;

		.ant-tabs-content-holder {
			flex: 1 0 0;
		}

		.wrap {
			margin: 0 auto;
			width: 100%;
			max-width: 768px;
		}

		> .content {
			display: flex;
			justify-content: center;
			margin: 0 16px;
			height: 100%;
			flex-direction: column;
		}

		.tabContent {
			display: flex;
			padding-top: 25px;
			height: 100%;
			flex-direction: column;
		}

		.ant-tabs {
			height: 100%;
		}

		.ant-tabs-content {
			height: 100%;
		}

		.ant-tabs-tabpane {
			height: 100%;
		}
	}
`;

export const UiToolBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const UiTabTitle = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	> span {
		margin-left: 12px;
	}
`;

export const UiRuleConfigContent = styled.div`
	overflow-y: auto;
	margin: 0 auto;
	padding: 20px;
	width: 100%;
	max-width: 800px;
	height: 100%;

	.ruleAddContentBtn {
		display: flex;
		justify-content: flex-end;
		margin: 24px auto;

		& > button {
			margin-left: 4px;
		}
	}
`;
