import styled from "styled-components";

export const UiCheckSetting = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px;
	height: 100%;

	> div {
		height: 100%;
		flex: 0 0 calc(50% - 5px);
	}
`;

export const UiTitleHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 24px;
	font-size: 16px;
	font-weight: 500;
`;

export const ListGroup = styled.div`
	width: 100%;
	max-width: 300px;

	.header {
		display: flex;
		padding: 16px 10px;
		background-color: #fafafa;
		border-bottom: 1px solid #f0f0f0;

		.listHead {
			flex: 1;
		}

		.actionHead {
			flex: 0 0 54px;
			text-align: center;
		}
	}

	.list {
		display: flex;
		justify-content: space-between;
		padding: 16px 10px;

		& + .list {
			border-top: 1px solid #f0f0f0;
		}
	}

	.listTitle {
		flex: 1;
	}

	.listAction {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 54px;
	}

	.requiredIcon {
		width: 16px;
		height: 16px;
		background-color: #1890ff;
		border-radius: 2px;
	}
`;
