import React from "react";
import PropTypes from "prop-types";
import RenderRoutes from "routes/RenderRoutes";
import { useHistory } from "react-router-dom";
import { StretchMenuLayout, Dropdown, Icon, AuthProvider, AuthMachine, Loading } from "common-components";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { BoxEditSolid } from "@onedesign/icon";
import { UiMainLayoutHeader, UiMenu, UiIconTitle, UiUserBox } from "./MainLayout.style";

const { useAuthDispatch, useAuthState } = AuthProvider;
const { eventType: AuthEventType } = AuthMachine;
/**
 * MainLayout
 */

const { Item } = Menu;

export const MainLayout = ({ routes = [] }) => {
	const authDispatch = useAuthDispatch();
	const authState = useAuthState();
	const history = useHistory();
	const { name, identity, account } = authState.context.result || {};
	const list = [
		{
			name: "登出",
			onClick: () => authDispatch(AuthEventType.LoggingOut),
			icon: "logout",
		},
	];
	return (
		<StretchMenuLayout
			env={process.env.REACT_APP_NODE_ENV}
			version={`v ${process.env.REACT_APP_VERSION || "1.0.0"}`}
			title={
				<UiIconTitle>
					<Icon name="eco" />
					影片庫
				</UiIconTitle>
			}
			header={
				<UiMainLayoutHeader>
					<h1 className="title" onClick={() => history.push("/")}>
						南一數位資源入庫平台
					</h1>
					<Dropdown list={list}>
						{name && (
							<UiUserBox>
								<img src={`/assets/roles/${identity}.png`} alt={identity} />
								{account} | {name}
							</UiUserBox>
						)}
					</Dropdown>
				</UiMainLayoutHeader>
			}
			sidebar={
				<UiMenu>
					<Menu mode="inline">
						<Item key="ruleSearch" icon={<Icon name="location_searching" />}>
							<Link to="/ruleSearch">規格查詢</Link>
						</Item>
						<Item key="ruleAdd" icon={<Icon name="note_add" type="outLined" />}>
							<Link to="/ruleAdd">規格新增</Link>
						</Item>
						<Item key="questionSearch" icon={<Icon name="find_in_page" type="outLined" />}>
							<Link to="/questionSearch">試題查詢</Link>
						</Item>
						<Item key="book" icon={<Icon name="unarchive" type="outLined" />}>
							<Link to="/">試題入庫</Link>
						</Item>
						<Item key="questionRevise" icon={<BoxEditSolid />}>
							<Link to="/questionRevise">試題修改</Link>
						</Item>
						<Item key="videoUpload" icon={<Icon name="unarchive" type="outLined" />}>
							<Link to="/videoUpload">影片入庫</Link>
						</Item>
					</Menu>
				</UiMenu>
			}>
			{authState.value === "LoginDone" || authState.value === "UpdateUserProfile" ? (
				<RenderRoutes routes={routes} />
			) : (
				<Loading />
			)}
		</StretchMenuLayout>
	);
};

MainLayout.propTypes = {
	routes: PropTypes.array,
};
