import React, { useState, useMemo, useEffect, useRef } from "react";
import { Select, Breadcrumb, Button, Table, AuthProvider, Modal, Upload } from "common-components";

import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { getBankSelection, getBankBookSelection, getBankQuestion } from "api/bank";
import { setSelectOptions, sliceTableData, search, blobDownload } from "utils/common";
import { UiRuleSearchPage, UiWarp, UiButtons } from "./DialogSelect.style";
import { getSpecificationProduct } from "api/specification";
import { filterItemType, filterAuthorizeType } from "../../utils/common";

import { EDUCATIONAL, ITEM_TYPE, VIDEO_TYPE, STUDY_TYPE, KNOWLEDGE_TYPE, AUTHORIZE_TYPE } from "constants/index";

const initSendFormData = {
	education: "",
	subject: "",
	version: "",
	year: "",
	books: "",
	itemType: "品項",
	videoType: "自製",
	studyType: "自學資源",
	knowledgeType: "Y",
	authorizeType: "授權條件",
};



export const DialogSelect = ({ setUploadSelectInfo }) => {
	const [itemTypes, setItemTypes] = useState([]);
	const [authorizeTypes, setAuthorizeType] = useState([]);
	const [sendFormData, setSendFormData] = useState(initSendFormData);

	const [stateSelect, sendSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getBankSelection();
				console.log(result);
				const {
					data: { yearMap, eduMap, eduVersion, eduSubject, books },
				} = result;
				return {
					eduSubject: eduSubject || {},
					eduVersion: eduVersion || {},
					eduMap: eduMap ? setSelectOptions(eduMap) : [],
					yearMap: yearMap ? setSelectOptions(yearMap) : [],
					books: books || {},
				};
			},
		},
	});

	const [stateSpecificationProduct, sendSpecificationProduct] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const {
					data: {
						specList,
						selection: { bookMap },
					},
				} = await getSpecificationProduct(event.year);
				return {
					bookMap: setSelectOptions(bookMap || []),
				};
			},
		},
	});

	const { bookMap } = stateSpecificationProduct.context.result || {};
	const { eduSubject, eduVersion, eduMap, yearMap } = stateSelect.context.result || {};

	const eduVersionOption = useMemo(() => {
		if (!sendFormData.education || !eduVersion) return [];
		return setSelectOptions(eduVersion[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const eduSubjectOption = useMemo(() => {
		if (!sendFormData.education || !eduSubject) return [];
		return setSelectOptions(eduSubject[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const onSelectChange = (key, value) => {
		setSendFormData({
			...sendFormData,
			[key]: value,
		});
		sendSpecificationProduct(FetchEventType.Fetch, sendFormData);
		setUploadSelectInfo({
			...sendFormData,
			[key]: value,
		});
	};

	useEffect(() => {
		sendSelect(FetchEventType.Fetch);
		sendSpecificationProduct(FetchEventType.Fetch, { year: "109" });
	}, []);

	useEffect(() => {
		if (eduVersionOption.length === 0) return;
		setSendFormData({
			...sendFormData,
			version: eduVersionOption[0].value,
		});
	}, [eduVersionOption]);

	

	useEffect(() => {
		if (eduSubjectOption.length === 0 || !sendFormData.version) return;

		let data = {
			...sendFormData,
			subject: eduSubjectOption[0].value,
		};
		console.log("data", data);

		const items = filterItemType({ data, eduSubject });
		const authorizes = filterAuthorizeType({ data, eduSubject });
		setItemTypes(items);
		setAuthorizeType(authorizes);
		console.log("items", items);
		data = {
			...data,
			itemType: items[0] ? items[0].name : "",
			authorizeType: authorizes[0] ? authorizes[0].name : "",
		};
		setSendFormData(data);
	}, [sendFormData.version, eduSubjectOption]);

	useEffect(() => {
		if (stateSelect.matches(FetchStates.Resolved)) {
			setSendFormData({
				...sendFormData,
				year: yearMap[0].value,
				education: eduMap[0].value,
			});
		}
	}, [stateSelect.value]);

	return (
		<UiRuleSearchPage>
			<div className="uploadSelectContent">
				<div>必填：</div>
				<div className="selectSearchGroup">
					<Select
						placeholder="請選擇學年度"
						options={yearMap}
						value={sendFormData.year}
						onChange={(val) => {
							onSelectChange("year", val);
						}}
					/>
					<Select
						placeholder="請選擇學制"
						options={eduMap}
						value={sendFormData.education}
						onChange={(val) => {
							onSelectChange("education", val);
						}}
					/>
					{eduSubjectOption.length > 0 ? (
						<Select
							placeholder="請選擇科目"
							options={eduSubjectOption}
							value={sendFormData.subject}
							onChange={(val) => {
								onSelectChange("subject", val);
							}}
						/>
					) : (
						""
					)}

					{eduVersionOption.length > 0 ? (
						<Select
							placeholder="請選擇版本"
							options={eduVersionOption}
							value={sendFormData.version}
							onChange={(val) => {
								onSelectChange("version", val);
							}}
						/>
					) : (
						""
					)}
					{bookMap && bookMap.length > 0 ? <Select placeholder="冊次" options={bookMap} onChange={(val) => {}} /> : ""}
				</div>
			</div>
			<div className="uploadSelectContent">
				<div>選填</div>
				<div className="selectSearchGroup">
					<Select
						placeholder="品項"
						options={itemTypes}
						value={sendFormData.itemType}
						onChange={(val) => {
							onSelectChange("itemType", val);
						}}
					/>
					<Select
						placeholder="類型"
						options={VIDEO_TYPE}
						value={sendFormData.videoType}
						onChange={(val) => {
							onSelectChange("videoType", val);
						}}
					/>
					<Select
						placeholder="學習資源"
						options={STUDY_TYPE}
						value={sendFormData.studyType}
						onChange={(val) => {
							onSelectChange("studyType", val);
						}}
					/>
					<Select
						placeholder="對應知識向度"
						options={KNOWLEDGE_TYPE}
						value={sendFormData.knowledgeType}
						onChange={(val) => {
							onSelectChange("knowledgeType", val);
						}}
						//Y N
					/>
					<Select
						placeholder="授權條件"
						options={authorizeTypes}
						value={sendFormData.authorizeType}
						onChange={(val) => {
							onSelectChange("authorizeType", val);
						}}
					/>
				</div>
			</div>
		</UiRuleSearchPage>
	);
};

DialogSelect.propTypes = {};
