import styled from "styled-components";
import { space } from "styled-system";
export const UiStretchBox = styled.ul`
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 250px;
	list-style: none;
	${space}
	.ant-checkbox-group {
		width: 100%;
	}

	.ant-collapse {
		border: 0;

		.ant-collapse-item {
			border-bottom: 1px solid #f0f0f0;
		}
	}

	.ant-collapse-content {
		border-top: 1px solid #f0f0f0;

		.ant-checkbox-wrapper {
			display: flex;
			align-items: center;
			padding: 9px 16px;
			width: 100%;
		}
	}

	.ant-collapse-header,
	.ant-collapse-content-box {
		padding: 0 !important;
	}

	.ant-collapse-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row-reverse;

		.ant-checkbox-wrapper {
			margin-right: 8px;
		}
	}
`;
export const UiStretchTitle = styled.div`
	padding: 9px 8px 9px 16px;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.85);
	background-color: #fafafa;
	user-select: none;

	.ant-checkbox-wrapper {
		margin-right: 10px;
	}
`;
export const UiStretchItem = styled.li`
	position: relative;
	margin: 0;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.65);
	user-select: none;

	& > span {
		display: block;
		padding: 9px 8px 9px 16px;
	}

	& + li {
		border-top: 1px solid #f0f0f0;
	}

	.ant-checkbox-wrapper {
		margin-right: 10px;
	}
`;