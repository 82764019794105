import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Table } from "common-components";
import { UiRuleSearchCategoryContainer } from "./RuleSearchCategoryContainer.style";

import { getSpecificationCategory } from "api/specification";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";

/**
 * 類別表 container
 */

export const RuleSearchCategoryContainer = () => {
	const { id: uid } = useParams();

	useEffect(() => {
		sendCategory(FetchEventType.Fetch, {});
	}, []);

	// category machine
	const [categoryData, sendCategory] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSpecificationCategory(uid);
				const { metadata } = res.data;
				return metadata;
			},
		},
	});
	const categoryDataInfo = categoryData.context.result || [];
	return (
		<UiRuleSearchCategoryContainer>
			<Table variant="light">
				<Table.Header>
					<Table.Row>
						<Table.Item>代碼</Table.Item>
						<Table.Item>名稱</Table.Item>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{categoryDataInfo.map((item) => {
						return (
							<Table.Row key={item.code}>
								<Table.Item>{item.code}</Table.Item>
								<Table.Item>{item.name}</Table.Item>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</UiRuleSearchCategoryContainer>
	);
};

RuleSearchCategoryContainer.propTypes = {
	categoryDataInfo: PropTypes.array,
};
