import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import { TitleContainer, Button } from "common-components";
import EditGroup from "../EditGroup";
import { UiSourceContent, UiList, UiTitleHeader, UiClearButton } from "./SourceContent.style";

/**
 * 選擇出處內容
 */

export const SourceContent = ({
	sourceOptions = [],
	chooseSourceOptions = [],
	checkedList = [],
	clearData = () => {},
	addData = () => {},
	saveOptionsHandler = () => {},
	cancelOptionsHandler = () => {},
	mode = "ruleAdd",
}) => {
	const [contentSwitch, setContentSwitch] = useState(true);
	const initSourceContentSwitch = (mode) => {
		let switchMode = true;
		switch (mode) {
			case "ruleSearch":
				setContentSwitch(false);
				switchMode = false;
				break;
			default:
				break;
		}
		return switchMode;
	};

	useEffect(() => {
		initSourceContentSwitch(mode);
	}, []);

	const onChange = (val) => {
		addData && addData(val);
	};

	const onEditHandler = () => {
		setContentSwitch(true);
	};

	const onCancelHandler = () => {
		cancelOptionsHandler();
		setContentSwitch(false);
	};

	const onSaveHandler = () => {
		saveOptionsHandler();
		setContentSwitch(false);
	};
	return (
		<UiSourceContent>
			{mode === "ruleSearch" && (
				<div className="editBlock">
					<EditGroup onEdit={onEditHandler} onSave={onSaveHandler} onCancel={onCancelHandler} />
				</div>
			)}
			<div className="content">
				<TitleContainer header={ChooseSourcePreviewHeader()}>
					{chooseSourceOptions.length > 0 &&
						chooseSourceOptions.map((item, index) => <UiList key={index}>{item.label}</UiList>)}
				</TitleContainer>
				{contentSwitch && (
					<TitleContainer header={ChooseSourceHeader(clearData)}>
						<Checkbox.Group
							options={sourceOptions}
							value={checkedList}
							onChange={(e) => {
								onChange(e);
							}}
						/>
					</TitleContainer>
				)}
			</div>
		</UiSourceContent>
	);
};

export const ChooseSourcePreviewHeader = () => <UiTitleHeader>已選出處預覽</UiTitleHeader>;
export const ChooseSourceHeader = (clearData) => (
	<UiTitleHeader>
		選擇出處
		<UiClearButton>
			<Button.IconButton size="small" variant="white" full={false} iconName="close" onClick={clearData}>
				全部清除
			</Button.IconButton>
		</UiClearButton>
	</UiTitleHeader>
);

SourceContent.propTypes = {
	sourceOptions: PropTypes.array,
	chooseSourceOptions: PropTypes.array,
	checkedList: PropTypes.array,
	clearData: PropTypes.func,
	addData: PropTypes.func,
	saveOptionsHandler: PropTypes.func,
	mode: PropTypes.string,
	cancelOptionsHandler: PropTypes.func,
};
