import styled, { css } from "styled-components";
import { flexbox } from "styled-system";
export const UiAddListTable = styled.div`
	display: block;
`;
export const UiAddListTableRow = styled.div`
	display: flex;
	margin: 8px 0;
	padding: 25px 16px;
	border: 1px solid #e7e7ed;
	border-radius: 12px;
	${({
  isadmin
}) => isadmin && css`
			background: #dbe9ff;
			border: none;
		`}
`;
export const UiAddListTableItem = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	${flexbox}
`;
export const UiAddListTableHeader = styled.div`
	${UiAddListTableRow} {
		display: flex;
		margin: 8px 0;
		padding: 13.5px 16px;
		border: none;
	}
`;
export const UiAddListTableBody = styled.div``;