import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from "common-components";
import { AutoComplete } from "antd";
import { UiDragDropList, UiDragDropContainer, UiDragDropItem } from "./DragDropList.style";

/**
 * 拖拉列表
 */

const noop = () => {};
export const DragDropList = ({
	label = "向度",
	list = [],
	options = [],
	onChange = noop,
	onAdd = noop,
	onDelete = noop,
	message = "",
	onSelectChange = noop,
}) => {
	const haveDataList = useMemo(() => {
		return list ? list.filter((item) => item.value !== "") : [];
	}, [list]);
	return (
		<UiDragDropList>
			<div className="title">{label}</div>
			<DragDropContext
				onDragEnd={(result) => {
					const { source, destination } = result;
					if (!destination) return;
					let arr = Array.from(list);
					const [remove] = arr.splice(source.index, 1);
					arr.splice(destination.index, 0, remove);
					onChange(arr);
				}}>
				<Droppable droppableId="dropList">
					{(provided) => (
						<UiDragDropContainer ref={provided.innerRef} {...provided.droppableProps}>
							{list.length > 0 &&
								list.map((li, i, listArr) => (
									<Draggable draggableId={li.id} index={i} key={li.id}>
										{(p) => (
											<>
												<UiDragDropItem {...p.draggableProps} {...p.dragHandleProps} key={li.id} index={li.i}>
													<div className="drop" ref={p.innerRef}>
														<Icon name="menu" />
													</div>
													<AutoComplete
														style={{
															minWidth: 300,
														}}
														disabled={li.isdefault}
														options={options}
														value={li.value} //value=undefined時,不會去setValue
														onChange={(value, option) => onSelectChange(option, li.id)}
														placeholder="搜尋向度"
														filterOption={(inputValue, option) =>
															option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
														}
														allowClear={true}
													/>
													<div className="icon">
														{i === listArr.length - 1 && haveDataList.length + options.length > listArr.length && (
															<>
																<Icon name="add" onClick={onAdd} />
															</>
														)}
														{li.isdefault ? "" : <Icon name="delete_forever" onClick={() => onDelete(li.id)} />}
													</div>
												</UiDragDropItem>
												{p.placeholder}
											</>
										)}
									</Draggable>
								))}
						</UiDragDropContainer>
					)}
				</Droppable>
			</DragDropContext>
			{message && (
				<div className="message">
					<Icon name="cancel" color="#f00" />
					{message}
				</div>
			)}
		</UiDragDropList>
	);
};

DragDropList.propTypes = {
	label: PropTypes.node,
	list: PropTypes.array,
	options: PropTypes.array,
	onChange: PropTypes.func,
	onAdd: PropTypes.func,
	onDelete: PropTypes.func,
	message: PropTypes.string,
	onSelectChange: PropTypes.func,
};
