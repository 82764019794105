import React, { useState, useMemo, useEffect, useRef } from "react";
import { Select, Breadcrumb, Button, Table, AuthProvider, Modal, Upload } from "common-components";

import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { getBankSelection, getBankBookSelection, getBankQuestion } from "api/bank";
import { getRecordDetail } from "api/convert";
import { DragDropList } from "components";
import { exportFile } from "api/export";
import { setSelectOptions, sliceTableData, search, blobDownload } from "utils/common";
import { Checkbox, Tabs, Image } from "antd";
import { format } from "date-fns";
import { UiRuleSearchPage, UiWarp, UiButtons, UIEditorContent } from "./VideoUploadPage.style";
import { getSpecificationProduct } from "api/specification";
import { DialogSelect, EditorData } from "components";

const { Item } = Table;
/**
 * 試題查詢頁面
 */

const breadcrumbList = [
	{
		icon: "unarchive",
		name: "影片入庫",
		path: "/videoUpload",
	},
];

const initSendFormData = {
	education: "",
	subject: "",
	version: "",
	year: "",
	books: "",
};

const defaultColumnData = [
	{
		id: "uid",
		code: "uid",
		value: "試題ID",
		isdefault: true,
	},
	{
		id: "descContent",
		code: "descContent",
		value: "試題內容",
		isdefault: true,
	},
	{
		id: "QUES_TYPE",
		code: "QUES_TYPE",
		value: "題型",
		isdefault: true,
	},
	{
		id: "KNOWLEDGE",
		code: "KNOWLEDGE",
		value: "知識向度",
		isdefault: true,
	},
	{
		id: "SOURCE",
		code: "SOURCE",
		value: "出處",
		isdefault: true,
	},
];

const dialogType = {
	SELECT_UPLOAD: "select_upload",
	LINK: "link",
	UPLOAD: "upload",
	DOWNLOAD: "download",
	EXCEL_UPLOAD: "excelUpload",
};

const { useAuthState } = AuthProvider;

export const VideoUploadPage = () => {
	const authState = useAuthState();
	const userColConfig = authState.context.result.preference.columnOptions || [];
	const tableRef = useRef(null);
	const [checkedList, setCheckedList] = useState([]); //["0-0-0"]

	const [sendFormData, setSendFormData] = useState(initSendFormData);
	const [treeActiveKey, setTreeActiveKey] = useState(null);
	const [quesIDList, setQuesIDList] = useState([]);

	const [questionData, setQuestionData] = useState([]);
	const [specList, setSpecList] = useState([]);
	const [files, setFiles] = useState([]);
	const [uploadPopup, setUploadPopup] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDialogType, setIsDialogType] = useState(dialogType.UPLOAD);

	const [stateSelect, sendSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getBankSelection();
				console.log(result);
				const {
					data: { yearMap, eduMap, eduVersion, eduSubject, books },
				} = result;
				return {
					eduSubject: eduSubject || {},
					eduVersion: eduVersion || {},
					eduMap: eduMap ? setSelectOptions(eduMap) : [],
					yearMap: yearMap ? setSelectOptions(yearMap) : [],
					books: books || {},
				};
			},
		},
	});

	const [stateSpecificationProduct, sendSpecificationProduct] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const {
					data: {
						specList,
						selection: { bookMap },
					},
				} = await getSpecificationProduct(event.year);

				console.log(bookMap);
				const nextSpecList = specList.map((item) => {
					const bookList = item.books.map((book, index, arr) => {
						let bookName = "";
						bookMap.forEach((item) => {
							if (item.code === book) bookName = item.name;
						});
						return <span key={index}>{`${bookName}${index !== arr.length - 1 ? " | " : ""}`}</span>;
					});

					//貌似可以把值reset
					return {
						...item,
						bookList,
					};
				});
				return {
					bookMap: setSelectOptions(bookMap || []),
				};
			},
		},
	});

	const { bookMap } = stateSpecificationProduct.context.result || {};

	//搜尋
	const [stateBookSelect, sendBookSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getBankBookSelection(event.payload);
				console.log(result);
				const {
					data: { bookMap },
				} = result;
				return {
					bookMap,
				};
			},
		},
	});

	const { eduSubject, eduVersion, eduMap, yearMap } = stateSelect.context.result || {};

	const eduVersionOption = useMemo(() => {
		console.log("eduVersion", eduVersion);
		if (!sendFormData.education || !eduVersion) return [];
		return setSelectOptions(eduVersion[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const eduSubjectOption = useMemo(() => {
		if (!sendFormData.education || !eduSubject) return [];
		return setSelectOptions(eduSubject[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const formatTableColData = (list, defaultData = {}) => {
		let nextData = defaultData;
		list.forEach((item) => {
			nextData = {
				...nextData,
				[item.code]: {
					name: item.value,
					defaultValue: "--",
				},
			};
		});
		return nextData;
	};

	const onSelectChange = (key, value) => {
		console.log("sendFormData", sendFormData);
		setSendFormData({
			...sendFormData,
			[key]: value,
		});
		sendSpecificationProduct(FetchEventType.Fetch, sendFormData);
	};

	const searchBookMap = () => {
		if (Object.entries(sendFormData).findIndex(([, val]) => val === "") !== -1) return;
		setTreeActiveKey(null);
		setCheckedList([]);
		setQuestionData([]);
		setQuesIDList([]);
		sendBookSelect(FetchEventType.Fetch, { payload: sendFormData });
		console.log("sendFormData", sendFormData);
		sendSpecificationProduct(FetchEventType.Fetch, sendFormData);
	};

	useEffect(() => {
		const defaultColumnConfig = userColConfig.length > 0 ? userColConfig : defaultColumnData;
		const nextData = formatTableColData(defaultColumnConfig);
		sendSelect(FetchEventType.Fetch);
		sendSpecificationProduct(FetchEventType.Fetch, { year: "109" });
		// setList(defaultColumnConfig);
		// setTableColumn(nextData);
	}, []);

	useEffect(() => {
		if (eduVersionOption.length === 0) return;
		setSendFormData({
			...sendFormData,
			version: eduVersionOption[0].value,
		});
	}, [eduVersionOption]);

	useEffect(() => {
		if (eduSubjectOption.length === 0 || !sendFormData.version) return;
		setSendFormData({
			...sendFormData,
			subject: eduSubjectOption[0].value,
		});
	}, [sendFormData.version, eduSubjectOption]);

	useEffect(() => {
		if (stateSelect.matches(FetchStates.Resolved)) {
			setSendFormData({
				...sendFormData,
				year: yearMap[0].value,
				education: eduMap[0].value,
			});
		}
	}, [stateSelect.value]);

	//---------上傳-----------
	const toggleUploadPopupVisible = (type) => {
		setIsDialogType(type);
		setUploadPopup(true);
		setFiles([]);
	};

	const closeDialog = () => {
		setUploadPopup(false);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		setUploadPopup(false);
		setFiles([]);
	};
	const changeFileList = (value) => {
		setFiles(value);
	};

	const setUploadSelectInfo = (props) => {
		console.log("setUploadSelectInfo", props);
	};

	const uploadDialog = () => {
		return (
			<Modal
				visible={uploadPopup}
				onCancel={closeDialog}
				onOk={submitFiles}
				title="上傳試題"
				width={800}
				footer={
					<UiButtons>
						<Button variant="white" full={false} onClick={closeDialog} Key="cancel" loading={isLoading}>
							取消
						</Button>
						<Button
							variant="orange"
							full={false}
							onClick={submitFiles}
							Key="ok"
							loading={isLoading}
							disabled={files.length === 0}>
							確認
						</Button>
					</UiButtons>
				}>
				<DialogSelect setUploadSelectInfo={setUploadSelectInfo} />
				<Upload
					fileList={files}
					multiple={true}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳20個Microsoft Word 檔<br />
							(副檔名 : .doc/.docx)
						</>
					}
					acceptFileType=".doc,.docx"
				/>
			</Modal>
		);
	};

	const uploadLinkDialog = () => {
		return (
			<Modal
				visible={uploadPopup}
				onCancel={closeDialog}
				onOk={submitFiles}
				title="上傳試題"
				footer={
					<UiButtons>
						<Button variant="white" full={false} onClick={closeDialog} Key="cancel" loading={isLoading}>
							取消
						</Button>
						<Button
							variant="orange"
							full={false}
							onClick={submitFiles}
							Key="ok"
							loading={isLoading}
							disabled={files.length === 0}>
							確認
						</Button>
					</UiButtons>
				}>
				<Upload
					fileList={files}
					multiple={true}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳20個Microsoft Word 檔<br />
							(副檔名 : .doc/.docx)
						</>
					}
					acceptFileType=".doc,.docx"
				/>
			</Modal>
		);
	};

	const selectUploadDialog = () => {
		return (
			<Modal
				style={{ color: "#fff" }}
				visible={uploadPopup}
				onCancel={closeDialog}
				title="請選擇影片上傳方式"
				footer={<div></div>}>
				<div className="selectUploadBtns">
					<div className="btns">
						<Button
							variant="blue"
							full={false}
							onClick={() => selectUploadType("file")}
							Key="cancel"
							loading={isLoading}>
							實體影片
						</Button>
						<Button variant="blue" full={false} onClick={() => selectUploadType("link")} loading={isLoading}>
							影片連結
						</Button>
					</div>
				</div>
			</Modal>
		);
	};

	const editorDialog = () => {
		return (
			<Modal
				style={{ color: "#fff" }}
				visible={uploadPopup}
				onCancel={closeDialog}
				title="修改資料"
				footer={
					<UiButtons>
						<Button variant="white" full={false} onClick={closeDialog} Key="cancel" loading={isLoading}>
							取消
						</Button>
						<Button variant="orange" full={false} Key="ok" loading={isLoading} disabled={files.length === 0}>
							確認
						</Button>
					</UiButtons>
				}>
				<div className="selectUploadBtns">
					<div className="btns">
						<EditorData />
					</div>
				</div>
			</Modal>
		);
	};

	const selectUploadType = (type) => {
		switch (type) {
			case "file":
				toggleUploadPopupVisible(dialogType.UPLOAD);
				break;
			case "link":
				toggleUploadPopupVisible(dialogType.LINK);
				break;
			default:
				break;
		}
	};

	const showDialog = (type) => {
		console.log(type);
		switch (type) {
			case dialogType.SELECT_UPLOAD:
				return selectUploadDialog();
			case dialogType.UPLOAD:
				return uploadDialog();
			case dialogType.LINK:
				return uploadLinkDialog();
			case dialogType.EDITOR:
				return editorDialog();
			default:
				return;
		}
	};
	useEffect(() => {
		setSpecList([
			{
				select: false,
				name: "XXX",
				year: "109",
				edu_sub: "國小國語",
				version: "南一",
				bookList: ["第一冊", "第二冊"],
				status: "待確認",
				time: "2021/10/22",
				uploader: "horryt",
			},
		]);
	}, [setSpecList]);

	return (
		<div>
			<UiRuleSearchPage>
				<div className="breadBlock">
					<Breadcrumb data={breadcrumbList} />
				</div>
				<div className="searchToolBar">
					<div className="selectSearchGroup">
						<Select
							placeholder="請選擇學年度"
							options={yearMap}
							value={sendFormData.year}
							onChange={(val) => {
								onSelectChange("year", val);
							}}
						/>
						<Select
							placeholder="請選擇學制"
							options={eduMap}
							value={sendFormData.education}
							onChange={(val) => {
								onSelectChange("education", val);
							}}
						/>
						{eduSubjectOption.length > 0 ? (
							<Select
								placeholder="請選擇科目"
								options={eduSubjectOption}
								value={sendFormData.subject}
								onChange={(val) => {
									onSelectChange("subject", val);
								}}
							/>
						) : (
							""
						)}
						{eduVersionOption.length > 0 ? (
							<Select
								placeholder="請選擇版本"
								options={eduVersionOption}
								value={sendFormData.version}
								onChange={(val) => {
									onSelectChange("version", val);
								}}
							/>
						) : (
							""
						)}
						{console.log("bookMap", bookMap)}
						{bookMap && bookMap.length > 0 ? (
							<Select placeholder="冊次" options={bookMap} onChange={(val) => {}} />
						) : (
							""
						)}

						<Button.IconButton
							variant="blue"
							full={false}
							icon={
								<i className="material-icons" style={{ fontSize: "20px" }}>
									search
								</i>
							}
							onClick={searchBookMap}>
							搜尋
						</Button.IconButton>
					</div>
				</div>
				<div className="flexEndBox">
					<Button.IconButton
						variant="blue"
						full={false}
						iconName=""
						onClick={() => toggleUploadPopupVisible(dialogType.SELECT_UPLOAD)}>
						上傳影片
					</Button.IconButton>
					<Button.IconButton
						variant="blue"
						full={false}
						iconName=""
						onClick={() => toggleUploadPopupVisible(dialogType.DOWNLOAD)}>
						待入庫清單下載
					</Button.IconButton>
					<Button.IconButton
						variant="blue"
						full={false}
						iconName=""
						onClick={() => toggleUploadPopupVisible(dialogType.EXCEL_UPLOAD)}>
						清單上傳
					</Button.IconButton>
				</div>
				<div className="tableBlock">
					<Table>
						<Table.Header>
							<Table.Row>
								<Item flex="0.5">勾選</Item>
								<Item>檔案名稱</Item>
								<Item>學年度</Item>
								<Item>學制科目</Item>
								<Item>版本</Item>
								<Item>冊次</Item>
								<Item>檔案狀態</Item>
								<Item>上傳時間</Item>
								<Item>上傳者</Item>
								<Item></Item>
								<Item>操作</Item>
								<Item></Item>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{specList &&
								specList.map((item, index) => {
									return (
										<Table.Row key={index}>
											<Table.Item>
												<Checkbox onChange={(event) => {}} />
											</Table.Item>
											<Table.Item>{item.name}</Table.Item>
											<Table.Item>{item.year}</Table.Item>
											<Table.Item>
												<span className="subjectItem">{`${item.edu_sub}`}</span>
											</Table.Item>
											<Table.Item>{item.version}</Table.Item>
											<Table.Item>
												<UiWarp>{item.bookList}</UiWarp>
											</Table.Item>
											{/* <Table.Item>{format(new Date(item.version), "yyyy/MM/dd HH:mm")}</Table.Item> */}

											<Table.Item>{item.status}</Table.Item>
											<Table.Item>{item.time}</Table.Item>
											<Table.Item>{item.uploader}</Table.Item>
											<Table.Item flex="2">
												<Button.IconButton iconName="" onClick={() => {}}>
													觀看影片
												</Button.IconButton>
											</Table.Item>
											<Table.Item flex="2">
												<Button.IconButton iconName="" onClick={() => toggleUploadPopupVisible(dialogType.EDITOR)}>
													修改資料
												</Button.IconButton>
											</Table.Item>
											<Table.Item>
												<Button.IconButton iconName="" onClick={() => {}}>
													刪除
												</Button.IconButton>
											</Table.Item>
										</Table.Row>
									);
								})}
						</Table.Body>
					</Table>
				</div>
			</UiRuleSearchPage>
			<UIEditorContent>{showDialog(isDialogType)}</UIEditorContent>
		</div>
	);
};
