import styled from "styled-components";


export const UiRuleSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.filterBlock {
		flex: 0 0 36px;
	}
	.flexEndBox {
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
		> * {
			margin-right: 8px;
		}
	}
	.searchToolBar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			> * {
				margin-right: 8px;
			}
		}
	}

	.tableBlock {
		overflow-y: auto;
		margin-top: 24px;
		height: 100%;

		.eduItem {
			color: #ceff8f;
		}

		.subjectItem {
			color: #fff48e;
		}
	}

	.pageBlock {
		margin-top: 25px;
	}

	.selectUploadBtns {
		display: flex;
		justify-content: center;

		overflow: auto;
		height: 100%;
		.btns {
			display: flex;
			> * {
				margin-right: 8px;
			}
		}
	}

	.ant-modal-title {
		color: #000;
	}
	.ant-modal-wrap {
		overflow: hidden;
	}
	
	.ant-modal-content {
		height: 100%;
	}
	.ant-modal-body {
		height: 71%;
	}
`;

export const UIEditorContent = styled.span`
	.ant-modal {
		top: 5%;
		height: 100%;
		padding-bottom: 5%;
	}
	.ant-modal-title {
		color: #000;
	}
	.ant-modal-wrap {
		overflow: hidden;
	}

	.ant-modal-content {
		height: 100%;
	}
	.ant-modal-body {
		height: 84%;
		overflow: auto;
	}
`;

export const UiWarp = styled.span`
	flex-wrap: wrap;
`;

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;
