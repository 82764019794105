import styled from "styled-components";
import { Modal as AntModal } from "antd";
export const UiModal = styled(AntModal)`
	.ant-modal-body {
		& > div + div {
			margin-top: 10px;
		}
	}

	.ant-modal-title {
		display: flex;
		align-items: center;
		color: #40a9ff;

		& > i {
			margin-right: 5px;
			color: #40a9ff;
		}
	}

	.ant-dropdown-menu-item {
		& > div {
			display: flex;
			align-items: center;
		}

		i {
			margin-right: 5px;
			font-size: 14px;
		}
	}

	.ant-modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 4px 16px 16px;
		border-top: none;
	}
`;
export const UiWaringModal = styled(AntModal)`
	.ant-modal-body {
		padding: 16px 16px 4px;
	}

	.ant-modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 4px 16px 16px;
		border-top: none;
	}

	.content {
		display: flex;
		align-items: center;

		> .icon {
			flex: 0 0 14px;
			display: inline-block;
			margin-right: 8px;
			width: 14px;
			height: 14px;
			font-size: 10px;
			text-align: center;
			color: #fff;
			background-color: #ffb802;
			border-radius: 50%;
			line-height: 14px;
		}
	}
`;
export const UiErrorModal = styled(UiWaringModal)`
	.content {
		> .icon {
			background-color: #f5222d;
		}
	}
`;