export const COOKIE_LOGIN_TOKEN_NAME = "nani_oneclass_resource_platform_token";
export const ROLES = {
  Editor: "編輯",
  Guest: "訪客"
};
export const EDUCATIONAL = [{
  name: "國小",
  value: "E"
}, {
  name: "國中",
  value: "J"
}, {
  name: "高中",
  value: "H"
}];
export const DATEFORMAT = "YYYY-MM-DD";