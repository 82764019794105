import Api from "../index"; // import { checkNaniLinkLogin } from "services/oneClub";

/**
 * 取得登錄資料
 */

export const loginByOneClub = async ({
  url,
  jwt
}) => {
  const response = await Api.get(`${url}/api/Login`, {}, {
    headers: {
      Authorization: jwt || null
    }
  });
  return response;
};
export const loginGetUserProfile = async ({
  url
}) => {
  const response = await Api.get(`${url}/api/Member/Profile`);
  return response;
};
export const setUserProfile = async ({
  url,
  payload
}) => {
  const response = await Api.post(`${url}/api/Member/Profile`, payload);
  return response;
};