import styled from "styled-components";
import { variant } from "styled-system";
export const UiDatePicker = styled.div`
	display: block;

	> .ant-picker {
		${variant({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
})}
		display: flex;

		input,
		span > span {
			${variant({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
})}
		}
	}
`;