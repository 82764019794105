import styled from "styled-components";

export const UiLoginPage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;

	.wrapper {
		background-color: #fff;
		border-radius: 5px;

		.errorMessage {
			display: flex;
			align-items: center;
			padding: 16px 24px;
			font-size: 20px;
			color: #fff;
			background-color: #ff4d4f;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;

			& > i {
				margin-right: 5px;
			}
		}

		.content {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 24px 32px;
			flex-direction: column;

			.iconBox {
				i {
					font-size: 72px;
					color: #52c41a;
				}
			}

			.subTitle {
				font-size: 20px;
				color: #98989e;
			}

			.title {
				color: #3f8ae0;
			}

			.largeTitle {
				font-size: 32px;
			}

			.prompt {
				padding: 3px 0;
				color: #ff4d4f;
			}

			.btnBox {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;
				width: 100%;

				& > button {
					margin: 0 5px;
					width: max-content;
				}
			}

			.form {
				width: 100%;

				& > * {
					margin-bottom: 20px;
				}
			}

			.btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin: 10px auto;
				padding: 5px 0;
				width: 100%;
				height: unset;
				font-size: 1.2rem;
				color: #fff;
				background-color: #3f8ae0;
				border-color: #3f8ae0;
				border-radius: 3px;
				box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
				text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
				transition: 0.5s;
				cursor: pointer;

				&:hover {
					background: #40a9ff;
					border-color: #40a9ff;
				}
			}

			.links {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;

				a {
					padding: 0 10px;
					color: rgba(0, 0, 0, 0.65);
					transition: 0.5s;

					&:hover {
						color: #3f8ae0;
					}
				}

				div {
					color: #98989e;
				}
			}
		}
	}
`;
