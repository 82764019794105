import styled from "styled-components";
export const UiLoading = styled.div`
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: max-content;
	transform: translateY(-50%);
`;