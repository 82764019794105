import {
	HomePage,
	LoginPage,
	RuleAddPage,
	RuleSearchPage,
	RuleSearchDetailPage,
	QuestionSearchPage,
	QuestionRevisePage,
	VideoUploadPage,
} from "views";
import { MainLayout } from "layouts";
const routes = [
	{
		path: "/login",
		component: LoginPage,
		exact: true,
	},
	{
		path: "/",
		component: MainLayout,
		exact: true,
		routes: [
			{
				path: "/",
				component: HomePage,
				name: "home",
				exact: true,
			},
		],
	},
	{
		path: "/ruleAdd",
		component: MainLayout,
		routes: [
			{
				path: "/ruleAdd",
				component: RuleAddPage,
				name: "ruleAdd",
				exact: true,
			},
		],
	},
	{
		path: "/ruleSearch",
		component: MainLayout,
		routes: [
			{
				path: "/ruleSearch",
				component: RuleSearchPage,
				name: "ruleSearch",
				exact: true,
			},
		],
	},
	{
		path: "/questionSearch",
		component: MainLayout,
		routes: [
			{
				path: "/questionSearch",
				component: QuestionSearchPage,
				name: "questionSearch",
				exact: true,
			},
		],
	},
	{
		path: "/ruleSearchDetail/:id",
		component: MainLayout,
		routes: [
			{
				path: "/ruleSearchDetail/:id",
				component: RuleSearchDetailPage,
				name: "ruleSearchDetail",
				exact: true,
			},
		],
	},
	{
		path: "/questionRevise",
		component: MainLayout,
		routes: [
			{
				path: "/questionRevise",
				component: QuestionRevisePage,
				name: "questionRevise",
				exact: true,
			},
		],
	},
	{
		path: "/videoUpload",
		component: MainLayout,
		routes: [
			{
				path: "/videoUpload",
				component: VideoUploadPage,
				name: "videoUpload",
				exact: true,
			},
		],
	},
];
export default routes;
