import React, { useState, useMemo, useEffect, useRef } from "react";
import { Select, Input, Button, Table, AuthProvider, Modal, Upload } from "common-components";

import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { getBankSelection, getBankBookSelection, getBankQuestion } from "api/bank";
import { setSelectOptions, sliceTableData, search, blobDownload } from "utils/common";
import { UiRuleSearchPage, UiWarp, UiButtons } from "./EditorData.style";
import { getSpecificationProduct, getChapter } from "api/specification";
import { getChapterBooks } from "api/chapter";
import { filterItemType, filterAuthorizeType, filterAttributeType } from "../../utils/common";

import { EDUCATIONAL, ITEM_TYPE, VIDEO_TYPE, STUDY_TYPE, AUTHORIZE_RANGE_TYPE, AUTHORIZE_TYPE } from "constants/index";

const initSendFormData = {
	education: "",
	subject: "",
	version: "",
	year: "",
	books: "",
	chapter: "",
	section: "",
	itemType: "品項",
	attribute: "N",
	videoType: "自製",
	studyType: "自學資源",
	knowledgeType: "Y",
	authorizeType: "授權條件",
	makerYear: "2021",
	startDate: "2021/10/30",
	endDate: "2021/10/30",
	correspondKnowledge: "Y",
	authorizeRange: "1.產品使用範圍：光碟產品，如電子書、DVD",
};

export const EditorData = () => {
	const [itemTypes, setItemTypes] = useState([]);
	const [attribute, setAttribute] = useState([]);
	const [authorizeTypes, setAuthorizeType] = useState([]);

	const [chapters, setChapters] = useState([]);
	const [sections, setSections] = useState([]);
	const [sendFormData, setSendFormData] = useState(initSendFormData);

	const [stateSelect, sendSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getBankSelection();
				console.log(result);
				const {
					data: { yearMap, eduMap, eduVersion, eduSubject, books },
				} = result;
				return {
					eduSubject: eduSubject || {},
					eduVersion: eduVersion || {},
					eduMap: eduMap ? setSelectOptions(eduMap) : [],
					yearMap: yearMap ? setSelectOptions(yearMap) : [],
					books: books || {},
				};
			},
		},
	});

	const [stateSpecificationProduct, sendSpecificationProduct] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const {
					data: {
						specList,
						selection: { bookMap },
					},
				} = await getSpecificationProduct(event.year);
				return {
					bookMap: setSelectOptions(bookMap || []),
				};
			},
		},
	});

	const { bookMap } = stateSpecificationProduct.context.result || {};
	const { eduSubject, eduVersion, eduMap, yearMap } = stateSelect.context.result || {};

	const eduVersionOption = useMemo(() => {
		if (!sendFormData.education || !eduVersion) return [];
		return setSelectOptions(eduVersion[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const eduSubjectOption = useMemo(() => {
		if (!sendFormData.education || !eduSubject) return [];
		return setSelectOptions(eduSubject[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const onSelectChange = async (key, value) => {
		const data = {
			...sendFormData,
			[key]: value,
		};
		setSendFormData(data);

		sendSpecificationProduct(FetchEventType.Fetch, sendFormData);

		const { year, version, education, subject, books } = data;
		if (year && version && education && subject && books) {
			const res = await getChapterBooks(`${year}${version}-${education}${subject}${books}`);

			if (res.isSuccess) {
				const chapters = filterChapters(res.data.chapters);
				setChapters(chapters);
				data.chapter ? setSections(filterSections(data.chapter, res.data.chapters)) : setSections([]);
			}
		}

		console.log("data", data);
		const items = filterItemType({ data, eduSubject });
		const authorizes = filterAuthorizeType({ data, eduSubject });
		const attribute = filterAttributeType({ data, eduSubject });
		console.log("items", items);
		setItemTypes(items);
		setAuthorizeType(authorizes);
		setAttribute(attribute);
	};

	useEffect(async () => {
		sendSelect(FetchEventType.Fetch);
		sendSpecificationProduct(FetchEventType.Fetch, { year: "109" });
	}, []);

	useEffect(() => {
		if (eduVersionOption.length === 0) return;
		setSendFormData({
			...sendFormData,
			version: eduVersionOption[0].value,
		});
	}, [eduVersionOption]);

	useEffect(() => {
		if (eduSubjectOption.length === 0 || !sendFormData.version) return;

		let data = {
			...sendFormData,
			subject: eduSubjectOption[0].value,
		};
		console.log(data);
		const items = filterItemType({ data, eduSubject });
		const authorizes = filterAuthorizeType({ data, eduSubject });
		const attribute = filterAttributeType({ data, eduSubject });
		setItemTypes(items);
		setAuthorizeType(authorizes);
		setAttribute(attribute);
		setSendFormData(data);
	}, [sendFormData.version, eduSubjectOption]);

	useEffect(() => {
		if (stateSelect.matches(FetchStates.Resolved)) {
			setSendFormData({
				...sendFormData,
				year: yearMap[0].value,
				education: eduMap[0].value,
			});
		}
	}, [stateSelect.value]);

	const filterChapters = (chapters) => {
		const arr = [];
		chapters.map((info) => {
			if (!info.parentCode) {
				arr.push({ name: info.code, value: info.code });
			}
			return null;
		});
		return arr;
	};

	const filterSections = (chapter, sections) => {
		console.log(chapter, "sections", sections);
		const arr = [];
		sections.map((info) => {
			if (info.parentCode === chapter) {
				const section = info.code.split("-")[1];
				console.log("section", section);
				arr.push({ name: section, value: section });
			}
			return null;
		});
		return arr;
	};

	return (
		<UiRuleSearchPage>
			<div className="editorContent">
				<div className="selectSearchGroup">
					<Select
						placeholder="請選擇學年度"
						options={yearMap}
						value={sendFormData.year}
						onChange={(val) => {
							onSelectChange("year", val);
						}}
					/>
					<Select
						placeholder="請選擇學制"
						options={eduMap}
						value={sendFormData.education}
						onChange={(val) => {
							onSelectChange("education", val);
						}}
					/>
					{eduSubjectOption.length > 0 ? (
						<Select
							placeholder="請選擇科目"
							options={eduSubjectOption}
							value={sendFormData.subject}
							onChange={(val) => {
								onSelectChange("subject", val);
							}}
						/>
					) : (
						""
					)}

					{eduVersionOption.length > 0 ? (
						<Select
							placeholder="請選擇版本"
							options={eduVersionOption}
							value={sendFormData.version}
							onChange={(val) => {
								onSelectChange("version", val);
							}}
						/>
					) : (
						""
					)}
					{bookMap && bookMap.length > 0 ? (
						<Select placeholder="冊次" options={bookMap} onChange={(val) => onSelectChange("books", val)} />
					) : (
						""
					)}
					{chapters.length > 0 ? (
						<Select
							placeholder="章"
							options={chapters}
							value={sendFormData.chapter}
							onChange={(val) => {
								onSelectChange("chapter", val);
							}}
						/>
					) : (
						""
					)}
					{sections.length > 0 ? (
						<Select
							placeholder="章"
							options={sections}
							value={sendFormData.section}
							onChange={(val) => {
								onSelectChange("section", val);
							}}
						/>
					) : (
						""
					)}
					<Select
						placeholder="品項"
						options={itemTypes}
						value={sendFormData.itemType}
						onChange={(val) => {
							onSelectChange("itemType", val);
						}}
					/>
					<Select
						placeholder="屬性"
						options={attribute}
						value={sendFormData.attribute}
						onChange={(val) => {
							onSelectChange("attribute", val);
						}}
					/>
					<div>
						<div>00:02:00</div>
					</div>
					<div>
						<div>漢字說故事</div>
					</div>
					<div>
						<div>Same</div>
					</div>
					<Select
						placeholder="學習資源"
						options={STUDY_TYPE}
						value={sendFormData.studyType}
						onChange={(val) => {
							onSelectChange("studyType", val);
						}}
					/>
					<Select
						placeholder="類型"
						options={VIDEO_TYPE}
						value={sendFormData.videoType}
						onChange={(val) => {
							onSelectChange("videoType", val);
						}}
					/>
					<Select
						placeholder="授權(製作)單位"
						options={authorizeTypes}
						value={sendFormData.authorizeType}
						onChange={(val) => {
							onSelectChange("authorizeType", val);
						}}
					/>
					<Select
						placeholder="製作年份"
						options={yearMap}
						value={sendFormData.makerYear}
						onChange={(val) => {
							onSelectChange("makerYear", val);
						}}
					/>
					<Select
						placeholder="使用開始日期"
						options={yearMap}
						value={sendFormData.startDate}
						onChange={(val) => {
							onSelectChange("startDate", val);
						}}
					/>
					<Select
						placeholder="使用開始日期"
						options={yearMap}
						value={sendFormData.endDate}
						onChange={(val) => {
							onSelectChange("endDate", val);
						}}
					/>
					<Select
						placeholder="是否對應知識向度"
						options={[
							{ name: "Y", value: "Y" },
							{ name: "N", value: "N" },
						]}
						value={sendFormData.correspondKnowledge}
						onChange={(val) => {
							onSelectChange("endDate", val);
						}}
					/>
					<Input //對應知識向度1
						placeholder="請輸入(限10字)*"
						row
						maxLength={10}
						value={sendFormData.subject + sendFormData.education}
						onChange={(e) => {}}
					/>
					<Select
						placeholder="對應知識向度時間1"
						options={yearMap}
						value={sendFormData.endDate}
						onChange={(val) => {}}
					/>
					<Input //對應知識向度2
						placeholder="請輸入(限10字)*"
						row
						maxLength={10}
						value={sendFormData.subject + sendFormData.education}
						onChange={(e) => {}}
					/>
					<Select
						placeholder="對應知識向度時間2"
						options={yearMap}
						value={sendFormData.endDate}
						onChange={(val) => {}}
					/>
					<Input //對應知識向度3
						placeholder="請輸入(限10字)*"
						row
						maxLength={10}
						value={sendFormData.subject + sendFormData.education}
						onChange={(e) => {}}
					/>
					<Select
						placeholder="對應知識向度時間3"
						options={yearMap}
						value={sendFormData.endDate}
						onChange={(val) => {}}
					/>
					<Select
						placeholder="對應知識向度時間"
						options={yearMap}
						value={sendFormData.endDate}
						onChange={(val) => {}}
					/>
					<Select
						placeholder="關聯跨科知識向度"
						options={AUTHORIZE_RANGE_TYPE}
						value={sendFormData.authorizeRange}
						onChange={(val) => {}}
					/>
					<Select
						placeholder="肖像權"
						options={AUTHORIZE_RANGE_TYPE}
						value={sendFormData.authorizeRange}
						onChange={(val) => {}}
					/>
				</div>
			</div>
		</UiRuleSearchPage>
	);
};

EditorData.propTypes = {};
