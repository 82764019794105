import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import { Breadcrumb, Button, Icon } from "common-components";
import {
	RuleSearchChapterContainer,
	RuleSearchQuestionTypeContainer,
	RuleSearchCategoryContainer,
	RuleSearchSourceContainer,
	CheckSetting,
	RuleAddContent,
} from "components";
import { convertArrayToMap, arraysEqual } from "utils/common";
import { useHistoryBlock } from "utils/hooks/useHistoryBlock";
import { stringify } from "query-string";
import { useHistory } from "react-router-dom";
import { getSpecificationSetting, updateSetting, updateBasicSetting } from "api/specification";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { UiRuleSearchDetailPage, UiToolBar, UiTabTitle, UiRuleConfigContent } from "./RuleSearchDetailPage.style";

/**
 * 規格查詢頁
 */
export const renderToolBar = (infos, history) => {
	if (!infos) return "";
	const { uid, year, version, education, subject } = infos;
	const ruleData = {
		specUID: uid,
		year,
		version,
		education,
		subject,
	};
	const querystring = stringify(ruleData);
	return (
		<UiToolBar>
			<Button.IconButton
				variant="deepOrange"
				mr={2}
				iconName="file_copy"
				type="outLined"
				onClick={() => {
					history.push(`/ruleAdd?ruleId=${uid}`);
				}}>
				複製產品
			</Button.IconButton>
			<Button.IconButton
				variant="blue"
				iconName="unarchive"
				type="outLined"
				onClick={() => {
					history.push(`/?${querystring}`);
				}}>
				上傳試題
			</Button.IconButton>
		</UiToolBar>
	);
};

export const RuleSearchDetailPage = () => {
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(1);
	const { id: uid } = useParams();
	const [defaultSendFormData, setDefaultSendFormData] = useState(null);
	const [sendFormData, setSendFormData] = useState({});

	const [isEdit, setIsEdit] = useState(false);
	const { conditionBlock, renderBlockModal } = useHistoryBlock(history);

	useEffect(() => {
		send(FetchEventType.Fetch, {});
		sendCheckMeta(FetchEventType.Fetch, {});
		sendOutputMeta(FetchEventType.Fetch, {});
		// sendSelection(FetchEventType.Fetch);
	}, []);

	// checkSetting machine
	const [checkSettingData, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSpecificationSetting({
					uid: uid,
				});
				const { tabs, infos } = res.data;
				const tabsMap = convertArrayToMap(tabs, "code");
				return { tabsMap, infos };
			},
			updateData: (_context, event) => {
				return updateBasicSetting({ ...event.payload });
			},
		},
	});
	const { tabsMap, infos } = checkSettingData.context.result || {};

	// check machine
	const [checkMetaData, sendCheckMeta] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSpecificationSetting({
					type: "check",
					uid: uid,
				});
				return res.data;
			},
			updateData: (_context, event) => {
				return updateSetting(event.payload.type, { uid: uid, codes: event.payload.codes });
			},
		},
	});
	const checkMetas = checkMetaData.context.result || [];

	// output machine
	const [outputMetaData, sendOutputMeta] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSpecificationSetting({
					type: "output",
					uid: uid,
				});
				return res.data;
			},
			updateData: (_context, event) => {
				return updateSetting(event.payload.type, { uid: uid, codes: event.payload.codes });
			},
		},
	});
	const outputMetas = outputMetaData.context.result || [];

	const breadcrumbList = useMemo(() => {
		if (!infos) return [];
		return [
			{
				icon: "location_searching",
				name: "規格查詢",
				path: "/ruleSearch",
			},
			{
				icon: "remove_red_eye",
				name: `規格設定 - ${infos.name}`,
				path: "/ruleSearch",
			},
		];
	}, [infos]);

	const checkSendFormDataChange = useMemo(() => {
		if (!infos) return false;
		for (const prop in sendFormData) {
			if (sendFormData[prop] === "" || sendFormData[prop].length < 1) {
				return false;
			}
			if (prop !== "chapter") {
				if (sendFormData[prop] !== infos[prop]) {
					if (Array.isArray(sendFormData[prop])) {
						if (!arraysEqual(sendFormData[prop], prop === "bookIDs" ? infos.books : infos[prop])) return true;
					} else {
						return true;
					}
				}
			}
		}
		return false;
	}, [sendFormData]);

	const onChange = (data) => {
		setSendFormData({
			...data,
		});
	};

	const putDefaultSendFormData = (infos) => {
		const { books, education, name, subject, version, year, type } = infos;
		setDefaultSendFormData({
			bookIDs: books,
			education,
			name,
			subject,
			version,
			year,
			type,
		});
	};

	const updateSettingHandler = (payload) => {
		let nextData = {};
		switch (payload.type) {
			case "output":
				sendOutputMeta(FetchEventType.Update, { payload });
				break;
			case "check":
				sendCheckMeta(FetchEventType.Update, { payload });
				break;
			case "basic":
				nextData = { ...sendFormData, books: sendFormData.bookIDs };
				delete nextData.bookIDs;
				send(FetchEventType.Update, { payload: { ...infos, ...nextData } });
				break;
			default:
				break;
		}
	};

	const tabChangeHandler = (activePage) => {
		setCurrentTab(parseInt(activePage));
	};

	useEffect(() => {
		if (!infos) return;
		putDefaultSendFormData(infos);
	}, [infos]);

	useEffect(() => {
		conditionBlock(!checkSendFormDataChange); //data沒變傳true
	}, [checkSendFormDataChange]);

	return (
		<UiRuleSearchDetailPage>
			<div className="breadBlock">
				<Breadcrumb data={breadcrumbList} />
			</div>
			<div className="mainBlock">
				<Tabs defaultActiveKey="2" tabBarExtraContent={renderToolBar(infos, history)} onChange={tabChangeHandler}>
					<Tabs.TabPane
						tab={
							<UiTabTitle>
								<Icon name="note_alt" type="outLined" />
								<span>基本設定</span>
							</UiTabTitle>
						}
						key="1">
						<UiRuleConfigContent>
							<div className="ruleAddContentBtn">
								{!isEdit ? (
									<Button
										variant="blue"
										full={false}
										onClick={() => {
											setIsEdit(true);
										}}>
										編輯
									</Button>
								) : (
									<>
										<Button
											variant="white"
											full={false}
											onClick={() => {
												setIsEdit(false);
												putDefaultSendFormData(infos);
											}}>
											取消
										</Button>
										<Button
											variant="green"
											full={false}
											disabled={!checkSendFormDataChange}
											onClick={() => {
												updateSettingHandler({ type: "basic" });
												setIsEdit(false);
											}}>
											確定
										</Button>
									</>
								)}
							</div>
							<RuleAddContent.AddProduct
								data={defaultSendFormData}
								disabled={infos?.isUsed || false}
								isEdit={isEdit}
								onChange={onChange}
							/>
						</UiRuleConfigContent>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab={
							<UiTabTitle>
								<Icon name="settings" type="outLined" />
								<span>檢核設定</span>
							</UiTabTitle>
						}
						key="2">
						<CheckSetting
							initData={checkMetas}
							checkMetaData={checkMetas}
							outputMetaData={outputMetas}
							onSaveHandle={updateSettingHandler}
							currentTab={currentTab}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab={
							<UiTabTitle>
								<Icon name="table_chart" type="outLined" />
								<span>範圍表</span>
							</UiTabTitle>
						}
						key="3">
						<RuleSearchChapterContainer />
					</Tabs.TabPane>
					<Tabs.TabPane
						tab={
							<UiTabTitle>
								<Icon name="view_week" type="outLined" />
								<span>題型表</span>
							</UiTabTitle>
						}
						key="4">
						<div className="tabContent">
							<RuleSearchQuestionTypeContainer />
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab={
							<UiTabTitle>
								<Icon name="wysiwyg" type="outLined" />
								<span>出處表</span>
							</UiTabTitle>
						}
						key="5">
						<div className="tabContent">
							<RuleSearchSourceContainer />
						</div>
					</Tabs.TabPane>
					{tabsMap && tabsMap["Category"] && (
						<Tabs.TabPane
							tab={
								<UiTabTitle>
									<Icon name="web" type="outLined" />
									<span>類別表</span>
								</UiTabTitle>
							}
							key="6">
							<RuleSearchCategoryContainer />
						</Tabs.TabPane>
					)}
				</Tabs>
			</div>
			{renderBlockModal("有未儲存紀錄，是否要離開？")}
		</UiRuleSearchDetailPage>
	);
};
