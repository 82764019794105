import styled from "styled-components";

export const UiRuleSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.editorContent {
		display: block;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			flex: 0.8;
			> * {
				margin-right: 8px;
				margin-bottom: 8px;
			}
		}
	}

	.ant-modal-title {
		color: #000;
	}
	
`;
export const UiWarp = styled.span`
	flex-wrap: wrap;
`;

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;
