import Api from "../index";
export const getUserAuthority = async url => {
  console.log("url", url);
  const response = await Api.get(`${url}/api/Authority/UserProfile`).then(res => {
    return res;
  }).catch(error => {
    return {
      isSuccess: false,
      data: error.data
    };
  });
  console.log("responseresponse", response);
  return response;
};
export const getUserAuthorityPreference = async (url, payload) => {
  const {
    type,
    data
  } = payload;
  const response = await Api.post(`${url}/api/Authority/Preference/${type}`, data);
  return response;
};