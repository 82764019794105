import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const envConfig = require("firebaseConfig.json");

const {
	RESOURCE_LIBRARY_API_KEY,
	RESOURCE_LIBRARY_AUTH_DOMAIN,
	RESOURCE_LIBRARY_DATABASE_URL,
	RESOURCE_LIBRARY_PROJECT_ID,
	RESOURCE_LIBRARY_STORAGE_BUCKET,
} = envConfig[process.env.REACT_APP_NODE_ENV];

const firebaseConfig = {
	apiKey: RESOURCE_LIBRARY_API_KEY,
	authDomain: RESOURCE_LIBRARY_AUTH_DOMAIN,
	databaseURL: RESOURCE_LIBRARY_DATABASE_URL,
	projectId: RESOURCE_LIBRARY_PROJECT_ID,
	storageBucket: RESOURCE_LIBRARY_STORAGE_BUCKET,
};
export const useFirebase = (config = firebaseConfig) => {
	const [storage, setState] = useState(null);
	const initialize = useCallback(() => {
		let app;
		if (firebase.apps.length <= 0 || !firebase.app("tutor-course")) {
			app = firebase.initializeApp(config, "tutor-course");
		} else {
			app = firebase.app("tutor-course");
		}
		app
			.auth()
			.signInAnonymously()
			.then(() => {
				setState(app.storage());
			})
			.catch((err) => console.error("sign in anonymously error", err));
		setState({ storage: app.storage() });
	}, [config, setState]);

	useEffect(() => {
		if (!config) return;
		initialize();
	}, [config, initialize]);
	return { storage };
};
