/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Login } from "common-components";
import { UiLoginPage } from "./LoginPage.style";

/**
 * 登入頁面
 */
export const LoginPage = () => {
	return (
		<UiLoginPage>
			<Login />
		</UiLoginPage>
	);
};
