import styled from "styled-components";

export const UiRuleSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.filterBlock {
		flex: 0 0 36px;
	}

	.tableBlock {
		overflow-y: auto;
		margin-top: 24px;
		height: 100%;

		.eduItem {
			color: #ceff8f;
		}

		.subjectItem {
			color: #fff48e;
		}
	}

	.pageBlock {
		margin-top: 25px;
	}
`;
export const UiWarp = styled.span`
	flex-wrap: wrap;
`;
