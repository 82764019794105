import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { AuthProvider, AuthMachine, COOKIE_LOGIN_TOKEN_NAME, checkLogin } from "common-components";
const { useAuthState, useAuthDispatch } = AuthProvider;
const { state: AuthStates, eventType: AuthEventType } = AuthMachine;
const AuthGuard = ({ children, ...props }) => {
	return children;
};

const RouteWithSubRoutes = (route) => {
	const authDispatch = useAuthDispatch();
	const authState = useAuthState();
	const history = useHistory();
	useEffect(async () => {
		if (authState.matches(AuthStates.LoginDone) && checkLogin(COOKIE_LOGIN_TOKEN_NAME) === "") {
			authDispatch(AuthEventType.LoggingOut);
		}
		if (authState.matches(AuthStates.Idle)) history.push("/login");
	}, [authState.value]);

	return (
		<Route
			path={route.path}
			exact={route.exact}
			render={(props) => (
				<AuthGuard {...props} {...route}>
					<route.component {...props} {...route} routes={route.routes} />
				</AuthGuard>
			)}
		/>
	);
};

const RenderRoutes = ({ routes = [] }) => (
	<Switch>
		{routes.map((route, i) => (
			<RouteWithSubRoutes key={i} {...route} />
		))}
	</Switch>
);

export default RenderRoutes;
