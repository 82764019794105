import styled from "styled-components";
export const UiTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	max-height: 100%;
	background-color: #fff;
	border: 1px solid #f0f0f0;

	> .header {
		border-bottom: 1px solid #f0f0f0;
	}

	.container {
		display: flex;
		align-items: center;
		overflow-x: hidden;
		padding: 10px;
		flex-direction: column;
		flex: 1;
	}
`;