import { Api } from "common-components";
import { stringify } from "query-string";
/**
 * 取得試題查詢selection
 */
export const getBankSelection = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Bank/Selection`);
	return response;
};

/**
 * 取得試題查詢冊次selection
 */
export const getBankBookSelection = async (payload) => {
	const queryString = stringify(payload);
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Bank/BookSelection?${queryString}`);
	return response;
};
/**
 * 取得試題查詢冊次selection
 */
export const getBankQuestion = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Bank/Question/${bookID}`);
	return response;
};
