import styled from "styled-components";

export const UiSourceContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.ant-checkbox-group-item {
		display: flex;
		margin-right: 0;
		border-bottom: 1px solid #f0f0f0;
		padding: 8px 16px;
	}

	> .editBlock {
		margin-bottom: 14px;
	}

	> .content {
		display: flex;
		justify-content: space-between;
		padding: 10px;
		height: 100%;
		background-color: #f0f0f0;

		> div {
			height: 100%;
			flex: 0 1 100%;

			+ div {
				margin-left: 10px;
			}

			.container {
				flex: 1 0 0;
			}
		}
	}
`;

export const UiTitleHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	font-size: 20px;
	font-weight: 500;
	color: #1890ff;
`;

export const UiList = styled.div`
	padding: 8px 16px;
	width: 250px;
	border-bottom: 1px solid #f0f0f0;
	font-size: 14px;
`;

export const UiClearButton = styled.div`
	margin-left: 8px;
`;
