import styled from "styled-components";
import { space } from "styled-system";
export const UiBreadcrumb = styled.div`
	${space}
	display: block;

	.ant-breadcrumb {
		display: flex;
		align-items: center;

		span {
			display: flex;

			.ant-breadcrumb-link {
				display: flex;
				align-items: center;

				& > * {
					color: #98989e;
					transition: 0.5s;

					&:hover {
						color: #fff;
					}
				}
			}

			.ant-breadcrumb-separator {
				color: #fff;
			}
		}

		> span:last-child {
			.ant-breadcrumb-link {
				& > * {
					font-size: 20px;
					color: #fff;
				}

				i {
					font-size: 20px;
				}
			}
		}
	}

	.ant-breadcrumb-link {
		display: flex !important;

		a {
			display: flex;
			align-items: center;
		}

		i {
			font-size: 18px;
		}
	}
`;