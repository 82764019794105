import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { Checkbox as AntCheckbox, Col } from "antd";
import { getSpecificationSelection } from "api/specification";
import { setSelectOptions, arraysEqual } from "utils/common";
import { Input, Select } from "common-components";
import { UiRuleAddContent, UiRuleAddProductContent, UiCompleteStep } from "./RuleAddContent.style";

/**
 * 規格新增內容
 */

export const RuleAddContent = () => {
	return <UiRuleAddContent>RuleAddContent</UiRuleAddContent>;
};

const initSendFormData = {
	bookIDs: "",
	education: "",
	subject: "",
	version: "",
	year: "",
	name: "",
	type: "",
};
const getObjecthaveKey = (obj) => (Object.keys(obj).length > 0 ? true : false);
// 新增產品
export const AddProduct = ({ data = {}, disabled = false, isEdit = true, onChange = () => {} }) => {
	const [sendFormData, setSendFormData] = useState(initSendFormData);
	const [stateSelection, sendSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getSpecificationSelection();
				const { data: { typeMap, selection } = {} } = result;
				return {
					typeOption: setSelectOptions(typeMap),
					selection,
				};
			},
		},
	});
	const { selection, typeOption } = stateSelection.context.result || {};
	const nowSelectOptions = useMemo(() => {
		if (!sendFormData.type || !selection) return {};
		const { bookMap, eduMap, subjectMap, versionMap, yearMap } = selection[sendFormData.type];
		return {
			bookMap,
			eduMap,
			subjectMap,
			versionMap: versionMap,
			yearMap: setSelectOptions(yearMap),
		};
	}, [sendFormData, selection]);

	const versionOption = useMemo(() => {
		if (!sendFormData.type || !getObjecthaveKey(nowSelectOptions)) return [];
		let nextData =
			sendFormData.type === "Chapter"
				? nowSelectOptions.versionMap
				: sendFormData?.education
				? nowSelectOptions.versionMap[`${sendFormData.education}`]
				: [];
		return setSelectOptions(nextData);
	}, [sendFormData.type, nowSelectOptions?.versionMap, sendFormData.education]);
	const eduOption = useMemo(() => {
		if (!sendFormData.type || !getObjecthaveKey(nowSelectOptions)) return [];
		if (sendFormData.type === "Chapter") {
			if (!sendFormData.year || !sendFormData.version || !nowSelectOptions.eduMap) return [];
			return setSelectOptions(nowSelectOptions.eduMap[`${sendFormData.year}${sendFormData.version}`]);
		} else {
			if (!nowSelectOptions.eduMap) return [];
			return setSelectOptions(nowSelectOptions.eduMap);
		}
	}, [sendFormData.type, sendFormData.year, sendFormData.version, nowSelectOptions.bookMap]);

	const subjectOption = useMemo(() => {
		if (!sendFormData.type || !getObjecthaveKey(nowSelectOptions)) return [];
		if (sendFormData.type === "Chapter") {
			if (!sendFormData.year || !sendFormData.version || !sendFormData.education || !nowSelectOptions.subjectMap)
				return [];
			return setSelectOptions(
				nowSelectOptions.subjectMap[`${sendFormData.year}${sendFormData.version}-${sendFormData.education}`],
			);
		} else {
			if (!sendFormData.education) return [];
			return setSelectOptions(nowSelectOptions.subjectMap[`${sendFormData.education}`]);
		}
	}, [sendFormData.type, sendFormData.year, sendFormData.version, sendFormData.education, nowSelectOptions.subjectMap]);

	const bookOption = useMemo(() => {
		if (!sendFormData.type || !getObjecthaveKey(nowSelectOptions)) return [];
		if (sendFormData.type === "Chapter") {
			if (
				!sendFormData.year ||
				!sendFormData.version ||
				!sendFormData.education ||
				!sendFormData.subject ||
				!nowSelectOptions.bookMap
			)
				return [];
			return setSelectOptions(
				nowSelectOptions.bookMap[
					`${sendFormData.year}${sendFormData.version}-${sendFormData.education}${sendFormData.subject}`
				],
			);
		} else {
			if (!nowSelectOptions.bookMap) return [];
			return setSelectOptions(nowSelectOptions.bookMap);
		}
	}, [
		sendFormData.type,
		sendFormData.year,
		sendFormData.version,
		sendFormData.education,
		sendFormData.subject,
		nowSelectOptions.bookMap,
	]);

	const checkNeedClearBookIDs = useMemo(() => {
		if (!data) return true;
		for (const prop in sendFormData) {
			//規格詳細頁剛進入sendFormData.prop為空 加上data.name !== "" 來判斷是複製產品還是規格詳細頁
			if (data.name !== "" && (sendFormData[prop] === "" || sendFormData[prop].length < 1)) {
				return false;
			}
			if (prop !== "bookIDs") {
				//是否清除冊次不檢查冊次有無改變
				if (sendFormData[prop] !== data[prop]) {
					if (Array.isArray(sendFormData[prop])) {
						if (!arraysEqual(sendFormData[prop], prop === "bookIDs" ? data.books : data[prop])) return true;
					} else {
						return true;
					}
				}
			}
		}
		return false;
	}, [sendFormData]);

	const onChangeHandler = (type, val) => {
		const nextData = { ...sendFormData, [type]: val };
		setSendFormData(nextData);
	};
	useEffect(() => {
		onChange && onChange(sendFormData);
	}, [sendFormData]);
	useEffect(() => {
		sendSelection(FetchEventType.Fetch);
	}, []);
	useEffect(() => {
		if (!data) return;
		const { name, bookIDs, education, subject, version, year, type } = data;
		setSendFormData({
			name,
			bookIDs,
			education,
			subject,
			version,
			year,
			type,
		});
	}, [data]);
	//清除冊次
	useEffect(() => {
		if (!checkNeedClearBookIDs) return;
		if (sendFormData.type !== "Chapter") return;
		setSendFormData({
			...sendFormData,
			bookIDs: [],
		});
	}, [sendFormData.year, sendFormData.version, sendFormData.education, sendFormData.subject]);
	useEffect(() => {
		if (!checkNeedClearBookIDs) return;
		setSendFormData({
			...sendFormData,
			bookIDs: [],
		});
	}, [sendFormData.type]);

	return (
		<UiRuleAddProductContent>
			<div className="wrap">
				<Col>
					<Input
						label="產品名稱"
						placeholder="請輸入(限30字)*"
						row
						maxLength={30}
						disabled={!isEdit}
						value={sendFormData.name}
						onChange={(e) => {
							onChangeHandler("name", e.target.value);
						}}
					/>
				</Col>
				<Col span={12}>
					<div className="formItem">
						<Select
							disabled={disabled || !isEdit}
							label="課程"
							placeholder="請選擇*"
							row
							options={typeOption}
							value={sendFormData.type}
							onChange={(e) => {
								onChangeHandler("type", e);
							}}
						/>
					</div>
					{nowSelectOptions?.yearMap && nowSelectOptions?.yearMap.length > 0 ? (
						<div className="formItem">
							<Select
								disabled={disabled || !isEdit}
								label="學年度"
								placeholder="請選擇*"
								row
								options={nowSelectOptions?.yearMap || []}
								value={sendFormData.year}
								onChange={(e) => {
									onChangeHandler("year", e);
								}}
							/>
						</div>
					) : (
						""
					)}
					{Array.isArray(versionOption) && versionOption.length > 0 ? (
						<div className="formItem">
							<Select
								disabled={disabled || !isEdit}
								label="版本"
								placeholder="請選擇*"
								row
								options={versionOption}
								value={sendFormData.version}
								onChange={(e) => {
									onChangeHandler("version", e);
								}}
							/>
						</div>
					) : (
						""
					)}

					{eduOption.length > 0 ? (
						<div className="formItem">
							<Select
								disabled={disabled || !isEdit}
								label="學制"
								placeholder="請選擇*"
								row
								options={eduOption}
								value={sendFormData.education}
								onChange={(e) => {
									onChangeHandler("education", e);
								}}
							/>
						</div>
					) : (
						""
					)}
					{subjectOption.length > 0 ? (
						<div className="formItem">
							<Select
								disabled={disabled || !isEdit}
								label="科目"
								placeholder="請選擇*"
								row
								options={subjectOption}
								value={sendFormData.subject}
								onChange={(e) => {
									onChangeHandler("subject", e);
								}}
							/>
						</div>
					) : (
						""
					)}
				</Col>
				{bookOption.length > 0 ? (
					<Col>
						<div className="formItem">
							<div className="volumeItem">
								<div className="volumeItemLabel">冊次</div>
								<AntCheckbox.Group
									options={bookOption.map((item) => ({
										label: item.name,
										value: item.value,
									}))}
									value={sendFormData.bookIDs}
									disabled={disabled || !isEdit}
									onChange={(e) => {
										onChangeHandler("bookIDs", e);
									}}
								/>
							</div>
						</div>
					</Col>
				) : (
					""
				)}
			</div>
		</UiRuleAddProductContent>
	);
};

AddProduct.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	isEdit: PropTypes.bool,
};

// 完成步驟
export const CompleteStep = () => {
	return (
		<UiCompleteStep>
			<div className="desc">是否要以上述設定完成新增產品？</div>
			<div className="tip">（如後續需要調整，可至產品設定進行設定）</div>
		</UiCompleteStep>
	);
};

CompleteStep.propTypes = {
	onStepClickHandler: PropTypes.func,
};
